import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../../../Styles/preview.scss";
import usaidLogo from "../../../assets/imgs/usaid_logo.png";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default function PreviewARF(props) {
  const pathname = window.location.pathname.split("/");
  const [data, setData] = useState(null);
  const [participants, setParticipants] = useState(null);
  const actType = [
    "Training",
    "Workshop",
    "Meeting",
    "Technical Assistance",
    "Other",
  ];
  const [wkwkw_sig, setWkwpSig] = useState(null);
  const [rep_sig, setRepSig] = useState(null);

  useEffect(() => {
    fetch(`/api/arf/${pathname[3]}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/participants/details/${pathname[3]}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setParticipants(data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (data) {
      getWKWPSig(data?.WKWPRepSignature);
      getERepWPSig(data?.ERepSignature);
    }
  }, [data]);

  function getWKWPSig(file) {
    fetch(`/api/uploads/${file}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        const dataurl = await convertFileToBase64(data);
        setWkwpSig(dataurl);
      })
      .catch((e) => {});
  }

  function getERepWPSig(file) {
    fetch(`/api/uploads/${file}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        const dataurl = await convertFileToBase64(data);
        setRepSig(dataurl);
      })
      .catch((e) => {});
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const download = async () => {
    const page1 = document.getElementById("page1");
    const page2 = document.getElementById("page2");

    // Use the dimensions of the first page as the canvas dimensions
    const canvas = document.createElement("canvas");
    canvas.width = page2.offsetWidth;
    canvas.height = page2.offsetHeight;

    let type = canvas.width > canvas.height ? "l" : "p";
    // Create a new jsPDF instance
    const pdf = new jsPDF(type, "mm", [canvas.width, canvas.height]);

    // Define the function to add a page from an HTML element
    const addPage = async (element) => {
      // Use html2canvas to capture the content of the HTML element
      const scaledCanvas = await html2canvas(element, { scale: 1 });
      const canvas = document.createElement("canvas");
      canvas.width = scaledCanvas.width;
      canvas.height = scaledCanvas.height;
      canvas.getContext("2d").drawImage(scaledCanvas, 0, 0);

      // Convert the canvas content to a data URL
      const imgData = canvas.toDataURL("image/png");

      // Add the captured content as an image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

      // Check if it's the last page before adding a new one
      if (element !== page2) {
        pdf.addPage();
      }
    };

    // Add pages to the PDF
    await addPage(page1);
    await addPage(page2);

    // Save the PDF as a file
    pdf.save("ARF.pdf");
  };

  return (
    <div className="preview">
      <div className="btn">
        <button
          onClick={() => {
            download();
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Download PDF
        </button>
      </div>
      <div className="bg a4landscape" id="page1">
        <div className="logo-act">
          <img src={usaidLogo} alt="" />
          <h1>KENYA AND EAST AFRICA</h1>
        </div>
        <h3>Western Kenya Water Project (WKWP)</h3>

        <div className="act-details">
          <p className="title">
            <b>ACTIVITY REGISTRATION FORM</b>
          </p>
          <Items1
            txt1="Activity Name"
            txt1Val={data?.ActivityName}
            txt2="Activity Organizer/Convener"
            txt2Val={data?.ActivityOrganizer}
          />
          <Items1
            txt1="County"
            txt1Val={data?.County}
            txt2="Sub-County"
            txt2Val={data?.SubCounty}
          />
          <Items1
            txt1="Ward"
            txt1Val={data?.Ward}
            txt2="Village"
            txt2Val={data?.Village}
          />
          <Items1
            txt1="LATITUDE (hddd.dddddo N/S):"
            txt1Val={data?.Latitude}
            txt2="LONGITUDE (hddd.dddddo E):"
            txt2Val={data?.Longitude}
          />
          <div className="type">
            <p>Activity Type: </p>
            {actType &&
              actType.map((item, index) => {
                if (data?.ActivityType == item) {
                  return <CheckBox key={index} label={item} checked={true} />;
                } else {
                  return <CheckBox key={index} label={item} checked={false} />;
                }
              })}
          </div>

          <div className="itm">
            <p>
              Activity Sector: <span>{data?.ActivitySector}</span>
            </p>
          </div>
          <div className="d3">
            <p>
              Name of Facilitator: <span>{data?.FacilitatorName}</span>
            </p>
            <p>
              Contact: <span>{props.FacilitatorContact}</span>
            </p>
            <p>
              Organization: <span>{props.FacilitatorOrganisation}</span>
            </p>
          </div>
          <Items1
            txt1="If Activity name is Training, TRAINING TOPIC COVERED"
            txt1Val={data?.TrainingTopics}
            txt2="Training Sub-topics Covered:"
            txt2Val={data?.TrainingSubTopics}
          />

          <div className="itm">
            <div>
              <p>
                Date: <span>{data?.Date}</span>
              </p>
            </div>
          </div>
          <div className="itm act-desc">
            <p>
              Brief Description of Activity Covered:{" "}
              <span>{data?.ActivityDescription}</span>
            </p>
          </div>
        </div>
      </div>
      <div id="page2" className="bg a4landscape">
        <div className="atendees">
          <div className="h-content">
            <p>No</p>
            <p>Name & Title</p>
            <p>Organization/Group</p>
            <p>Email</p>
            <p>Tel</p>
            <p>PWD</p>
            <p>
              Age <br />
              (15-29) / 30+
            </p>
            <p>
              Sex <br />
              (M/F)
            </p>
            <p>Signature</p>
          </div>
          {participants &&
            participants.map((item, index) => {
              return (
                <div className="p-content" key={index}>
                  <p>{index + 1}</p>
                  <p>{item?.ParticipantName}</p>
                  <p>{item?.Organization}</p>
                  <p>{item?.Email}</p>
                  <p>{item?.Phone}</p>
                  <p>{item?.PWD}</p>
                  <p>{item?.Age}</p>
                  <p>{item?.Gender}</p>
                  <p>{item?.ParticipantName}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

const CheckBox = (props) => {
  return (
    <>
      {props.checked ? (
        <div>
          <input type="checkbox" checked />{" "}
          <label htmlFor={props.label}>{props.label}</label>
        </div>
      ) : (
        <div>
          <input type="checkbox" disabled />{" "}
          <label htmlFor={props.label}>{props.label}</label>
        </div>
      )}
    </>
  );
};

const Items1 = (props) => {
  return (
    <div className="item">
      <p>
        {props.txt1}: <span>{props.txt1Val}</span>
      </p>
      <p>
        {props.txt2}: <span>{props.txt2Val}</span>
      </p>
    </div>
  );
};
