

export default function IndicatorBox(props) {
  return (
    <div
      className={
        props?.item?.Code === props?.indicatorDetails?.Code
          ? "active user-box "
          : "user-box"
      }
      onClick={() => {
        props.setID(props?.item);
        props?.selected?.current.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <div className="left">
        <h2>{props?.item?.Code}</h2>
      </div>
      <div className="right">
        <p className="dark">{props?.item?.Description}</p>
      </div>
    </div>
  );
}
