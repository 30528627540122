import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import "../../../Styles/preview.scss";
import usaidLogo from "../../../assets/imgs/usaid_logo.png";
import wkwpLogo from "../../../assets/imgs/wkwp_logo.png";
import jsPDF from "jspdf";

export default function PreviewRMF() {
  const pathname = window.location.pathname.split("/");
  const [data, setData] = useState(null);
  const [wkwkw_sig, setWkwpSig] = useState(null);
  const [rep_sig, setRepSig] = useState(null);
  const beneficiaryType = [
    "WSP",
    "Water Enterprise",
    "WRA",
    "WRUA",
    "CFA",
    "County",
  ];
  const TATypes = [
    "Operational",
    "Management",
    "Engineering",
    "Capacity Building",
    "Finance",
    "Data & Digital Solutions",
  ];
  const TAThroughList = [
    "Training",
    "Grant",
    "Meeting/Coaching/Mentoring",
    "Other",
  ];

  const changeInSD = ["Yes", "No"];
  const regStatus = ["Current", "Expired"];
  const pfSource = ["Bank", "MFI", "SACCO", "Other"];
  const phfSource = ["Corporate CSR", "NGOs", "Foundations", "Other"];

  useEffect(() => {
    fetch(`/api/rmf/${pathname[3]}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  }, []);

  const downloadPdfDocument = () => {
    // Get references to the page elements
    const page1Element = document.getElementById("page1");
    const page2Element = document.getElementById("page2");
    const page3Element = document.getElementById("page3");
    const page4Element = document.getElementById("page4");

    // Create canvases for each page
    const canvas1 = document.createElement("canvas");
    const canvas2 = document.createElement("canvas");
    const canvas3 = document.createElement("canvas");
    const canvas4 = document.createElement("canvas");

    canvas1.width = page1Element.offsetWidth;
    canvas1.height = page1Element.offsetHeight;

    canvas2.width = page2Element.offsetWidth;
    canvas2.height = page2Element.offsetHeight;

    canvas3.width = page3Element.offsetWidth;
    canvas3.height = page3Element.offsetHeight;

    canvas4.width = page4Element.offsetWidth;
    canvas4.height = page4Element.offsetHeight;

    // Use html2canvas to capture the content of each page
    const promise1 = html2canvas(page1Element, { scale: 1, canvas: canvas1 });
    const promise2 = html2canvas(page2Element, { scale: 1, canvas: canvas2 });
    const promise3 = html2canvas(page3Element, { scale: 1, canvas: canvas3 });
    const promise4 = html2canvas(page4Element, { scale: 1, canvas: canvas4 });

    // Wait for all promises to resolve
    Promise.all([promise1, promise2, promise3, promise4]).then((canvases) => {
      const pdf = new jsPDF("p", "mm", "a4");
      // Add each page to the PDF
      canvases.forEach((canvas, index) => {
        if (index > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "JPEG",
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        );
      });
      // Save the PDF
      pdf.save("RMF.pdf");
    });
  };

  useEffect(() => {
    if (data) {
      getWKWPSig(data?.WKWPRepSignature);
      getERepWPSig(data?.ERepSignature);
    }
  }, [data]);

  function getWKWPSig(file) {
    fetch(`/api/uploads/${file}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        const dataurl = await convertFileToBase64(data);
        setWkwpSig(dataurl);
      })
      .catch((e) => {});
  }

  function getERepWPSig(file) {
    fetch(`/api/uploads/${file}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        const dataurl = await convertFileToBase64(data);
        setRepSig(dataurl);
      })
      .catch((e) => {});
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="preview">
      <div className="btn">
        <button
          onClick={() => {
            downloadPdfDocument();
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Download PDF
        </button>
      </div>
      <div className="bg">
        <div className="a4potrait" id="page1">
          <div className="logos">
            <img src={usaidLogo} alt="" />
            <img className="wkwp" src={wkwpLogo} alt="" />
          </div>
          <h3>WKWP RESULTS MONITORING TOOL</h3>

          <div className="form-content">
            <p>
              Name of Partner/Beneficiary:{" "}
              <span className="b-btm">{data?.SectorStakeholder}</span>
            </p>
            <div className="check-boxes b-btm">
              <p>Type of Partner/Beneficiary</p>
              {beneficiaryType &&
                beneficiaryType.map((item, index) => {
                  if (data?.StakeholderType === item) {
                    return <CheckBox key={index} label={item} checked={true} />;
                  } else {
                    return (
                      <CheckBox key={index} label={item} checked={false} />
                    );
                  }
                })}
            </div>

            <div className="dv3 b-btm">
              <p>
                County: <span>{data?.County}</span>
              </p>
              <p>
                Sub-county: <span>{data?.SubCounty}</span>
              </p>
              <p>
                Ward: <span>{data?.Ward}</span>
              </p>
            </div>
            <p className="b-btm">
              Village/Town: <span>{data?.Village}</span>
            </p>
            <p className="b-btm">
              Date of Visit/TA was provided: (dd/mm/yyyy):{" "}
              <span>{data?.Date}</span>
            </p>

            <div className="dv2 b-btm">
              <p>
                Longitude: <span>{data?.Longitude}</span>
              </p>
              <p>
                Latitude: <span>{data?.Latitude}</span>
              </p>
            </div>
            <div className="dv3 b-btm">
              <p>
                Contact Person Name: <span>{data?.ContactName}</span>
              </p>
              <p>
                Phone: <span>{data?.ContactPhone}</span>
              </p>
              <p>
                Designation: <span>{data?.Designation}</span>
              </p>
            </div>

            <div className="check-boxes b-btm">
              <p>Type of TA provided:</p>
              {TATypes &&
                TATypes.map((item, index) => {
                  if (data?.TA_Provided === item) {
                    return <CheckBox key={index} label={item} checked={true} />;
                  } else {
                    return (
                      <CheckBox key={index} label={item} checked={false} />
                    );
                  }
                })}
            </div>
            <div className="check-boxes b-btm">
              <p>TA through:</p>
              {TAThroughList &&
                TAThroughList.map((item, index) => {
                  if (data?.TAThrough === item) {
                    return <CheckBox key={index} label={item} checked={true} />;
                  } else {
                    return (
                      <CheckBox key={index} label={item} checked={false} />
                    );
                  }
                })}
            </div>

            <hr />
            <div className="check-boxes">
              <p>
                Has there been any change in service delivery since the last
                Technical Assistance?:
              </p>
              {changeInSD &&
                changeInSD.map((item, index) => {
                  if (data?.ChangeInSD === item) {
                    return <CheckBox key={index} label={item} checked={true} />;
                  } else {
                    return (
                      <CheckBox key={index} label={item} checked={false} />
                    );
                  }
                })}
            </div>
            <p className="b-btm">
              If yes, what has changed: <span>{data?.Change}</span>
            </p>
          </div>

          <div className="components">
            <h5>Rural and Urban Water Services</h5>
            <Items1
              label="Non-Revenue Water (%):"
              checked={false}
              itm="%"
              itmVal={data?.RU_NRW}
            />
            <Items3
              label="Revenue Collection"
              checked={false}
              itm1="Total Billing (KES)"
              itm1Val={data?.RU_TotalBilled}
              itm2="Total Collection (KES)"
              itm2Val={data?.RU_TotalCollected}
            />
            <Items1
              label="Water distribution pipelines constructed/rehabilitated (KM):"
              checked={false}
              itm="KM"
              itmVal={data?.RU_WaterPipelineConstructed}
            />
          </div>
        </div>
        <div className="a4potrait" id="page2">
          <div className="logos">
            <img src={usaidLogo} alt="" />
            <img className="wkwp" src={wkwpLogo} alt="" />
          </div>
          <div className="components">
            <Items3
              label="Number of new water connections:"
              checked={false}
              itm1="Individual Connections:"
              itm1Val={data?.RU_NWCIndividualConnection}
              itm2="Yard Taps"
              itm2Val={data?.RU_NWCYardTaps}
              itm3="Multi-Dwelling Units (Small, Medium, Large)"
              itm3Val={data?.RU_NWCMDU}
            />
            <Items3
              className="d3"
              label="Number of new water connections:"
              checked={false}
              itm1="Male:"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.RU_Newbeneficiaries_NewConnections}
            />
            <Items3
              className="d3"
              label="Number of beneficiaries benefiting from improved service:"
              checked={false}
              itm1="Male:"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.RU_Beneficiaries_ImprovedService}
            />
            <Items3
              label="Number of new beneficiaries of water from protected Springs and communal water points not managed by enterprises:"
              checked={false}
              className="d3"
              itm1="Male:"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.RU_Beneficiaries_IS_ProtectedSprings}
            />
            <Items3
              label="Number of beneficiaries benefiting from improved protected Springs and communal water points not managed by enterprises:"
              checked={false}
              className="d3"
              itm1="Male:"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.RU_Beneficiaries_ImprovedService}
            />
            <Items1
              label="Awarded a quality standard for utilities e.g., QMS - ISO 9001:2015 (KEBS); Lab Testing ISO17025:2017 (KENAS):"
              checked={false}
              itm="Name of Standard"
              itmVal={data?.RU_QualityStandard}
            />
            <Items1
              label="Volume of water produced (M3):"
              checked={false}
              itm="M3"
              itmVal={data?.RU_WaterProduced}
            />
            <div className="item">
              <div className="left">
                <CheckBox
                  label="Registration status (Tick appropriately):"
                  checked={false}
                />
              </div>
              <div className="right">
                <div className="d3">
                  {regStatus &&
                    regStatus.map((item, index) => {
                      if (data?.RU_RegistrationStatus === item) {
                        return (
                          <CheckBox key={index} label={item} checked={true} />
                        );
                      } else {
                        return (
                          <CheckBox key={index} label={item} checked={false} />
                        );
                      }
                    })}
                  {(data?.RU_RegistrationStatus !== "Current" ||
                    data?.RU_RegistrationStatus !== "Expired") && (
                    <p>
                      Other:{" "}
                      <span className="b-btm">
                        {data?.RU_RegistrationStatus}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Items3
              label="Change in Service Delivery Model (SDM):"
              checked={false}
              className="d3"
              itm1="From:"
              itm1Val={data?.RU_SDM_From}
              itm2="To:"
              itm2Val={data?.RU_SDM_To}
            />
            <Items1
              label="Water Storage (M3):"
              checked={false}
              itm="M3"
              itmVal={data?.RU_WaterStorage}
            />
            <Items1
              label="Hours of Supply:"
              checked={false}
              itm="Hrs"
              itmVal={data?.RU_SupplyHours}
            />
            <Items1
              label="Number of new Meters installed:"
              checked={false}
              itm="Hrs"
              itmVal={data?.RU_New_Meters}
            />
            <Items1
              label="Others (Specify):"
              checked={false}
              itm=""
              itmVal={null}
            />
            <h5>Water Resources Management</h5>
            <Items1
              label="Number of PES action plans developed"
              checked={false}
              itm="No."
              itmVal={data?.WRM_PES_Actions}
            />
            <Items1
              label="Number of Trees planted:"
              checked={false}
              itm="No."
              itmVal={data?.WRM_Trees_Planted}
            />
            <Items1
              label="Number of Springs Protected:"
              checked={false}
              itm="No."
              itmVal={data?.WRM_Springs_Protected}
            />
            <Items1
              label="Number of Kilometeres of terrace:"
              checked={false}
              itm="No."
              itmVal={data?.WRM_Terrace_Kilometers}
            />
            <Items3
              label="Number of Hectares of land rehabilitated:"
              checked={false}
              itm1="Riparian"
              itm1Val={data?.WRM_Riparian_Rehabilitated}
              itm2="Forest"
              itm2Val={data?.WRM_Forest_Rehabilitated}
            />
          </div>
        </div>

        <div className="a4potrait" id="page3">
          <div className="logos">
            <img src={usaidLogo} alt="" />
            <img src={wkwpLogo} className="wkwp" alt="" />
          </div>
          <div className="components">
            <Items1
              label="Number of Tree nurseries established"
              checked={false}
              itm="No.:"
              itmVal={data?.WRM_Tree_Nurseries}
            />
            <Items1
              label="Kilometers of River line protected"
              checked={false}
              itm="No.:"
              itmVal={data?.WRM_Riverine_Protected}
            />
            <Items3
              label="Number of beneficiaries benefiting from adoption and implementation of measures to improve water resources management:"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.WRM_Beneficiaries_Improved_WRM}
            />
            <Items3
              label="Number of beneficiaries benefiting from alternative/enhanced IGAs:"
              checked={false}
              className="d3"
              itm1="Male:"
              itm1Val={data?.WRM_Beneficiaries_IGA_HH}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.WRM_Beneficiaries_IGA_Total}
            />
            <Items3
              label="Number of WRUA committees trained on effective WRM practices:"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.WRM_WRUA_Committees_Trained}
            />
            <Items1
              label="Data management and digital solution adopted and operationalized/functional"
              checked={false}
              itm="Name:"
              itmVal={data?.WRM_Data_Digital_Solution}
            />
            <h5>Finance and Private Sector Engagement</h5>
            <Items3
              label="Amount of private funding invested by WSPs/Enterprises:"
              checked={false}
              itm1="Own Capital (Ksh):"
              itm1Val={data?.FPS_PrivateFunding_Own}
              itm2="Debt (Ksh):"
              itm2Val={data?.FPS_PrivateFunding_Debt}
            />
            <div className="item">
              <div className="left">
                <CheckBox label="Source of private funding:" checked={false} />
              </div>
              <div className="right">
                <div className="d3">
                  {pfSource &&
                    pfSource.map((item, index) => {
                      if (data?.FPS_PrivateFunding_Source === item) {
                        return (
                          <CheckBox key={index} label={item} checked={true} />
                        );
                      } else {
                        return (
                          <CheckBox key={index} label={item} checked={false} />
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            <Items1
              label="Amount of philanthropic funding mobilized WSPs/Enterprises"
              checked={false}
              itm="Kshs:"
              itmVal={data?.FPS_PhilanthropicFunding}
            />
            <div className="item">
              <div className="left">
                <CheckBox
                  label="Source of philanthropic funding:"
                  checked={false}
                />
              </div>
              <div className="right">
                <div className="d3">
                  {phfSource &&
                    phfSource.map((item, index) => {
                      if (data?.FPS_PhilanthropicFunding_Source === item) {
                        return (
                          <CheckBox key={index} label={item} checked={true} />
                        );
                      } else {
                        return (
                          <CheckBox key={index} label={item} checked={false} />
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            <Items3
              label="Amount of public funding invested by WSPs/Enterprises"
              checked={false}
              itm1="County Gov: (Kshs)"
              itm1Val={data?.FPS_PublicFunding_CountyGov}
              itm2="National Gov/Water Works Agencies: (Kshs)"
              itm2Val={data?.FPS_PublicFunding_NationalGov}
            />
            <Items3
              label="Number of new beneficiaries benefiting from private funding investment"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.FPS_NewBeneficiaries_PrivateFunding}
            />
            <Items3
              label="Number of beneficiaries benefiting from improved service as a result of private funding investment"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.FPS_IS_Beneficiaries_PrivateFunding}
            />
            <Items3
              label="Number of new beneficiaries benefiting from philanthropic funding investment"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.FPS_NewBeneficiaries_PhilanthropicFunding}
            />
            <Items3
              label="Number of beneficiaries benefiting from improved service as a result of philanthropic funding investment"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.FPS_IS_Beneficiaries_PhilanthropicFunding}
            />
            <Items3
              label="Number of new beneficiaries benefiting from public funding investment"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.FPS_NewBeneficiaries_PublicFunding}
            />
            <Items3
              label="Number of beneficiaries benefiting from improved service as a result of public funding investment"
              checked={false}
              className="d3"
              itm1="Male"
              itm1Val={null}
              itm2="Female"
              itm2Val={null}
              itm3="Total"
              itm3Val={data?.FPS_IS_Beneficiaries_PublicFunding}
            />
          </div>
        </div>
        <div className="a4potrait" id="page4">
          <div className="logos">
            <img src={usaidLogo} alt="" />
            <img src={wkwpLogo} className="wkwp" alt="" />
          </div>
          <div className="components">
            <h5>Policy and Governance</h5>
            <Items1
              label="Number of water policies, laws and guidelines developed/ reviewed"
              checked={false}
              itm="No.:"
              itmVal={data?.PG_Policies_Developed}
            />
            <Items1
              label="Number of county and government officials trained in corporate governance"
              checked={false}
              itm="No.:"
              itmVal={data?.PG_Officials_Trained}
            />
            <Items1
              label="Brief Description if necessary"
              checked={false}
              itm="No.:"
              itmVal={data?.PG_Description}
            />
          </div>
          <div className="desc">
            <h5>Brief Description if necessary:</h5>
            <p>{data?.Description}</p>
          </div>
          <div className="signature">
            <div>
              <h5>Entity Representative</h5>
              <p>
                Name: <span className="b-btm">{data?.ERepName}</span>
              </p>
              <p>
                Designation:{" "}
                <span className="b-btm">{data?.ERepDesignation}</span>
              </p>
              <p>
                Signature/official stamp:{" "}
                <span className="b-btm">
                  <img src={rep_sig} />
                </span>
              </p>
            </div>
            <div>
              <h5>WKWP Representative</h5>
              <p>
                Name: <span className="b-btm">{data?.WKWPRepName}</span>
              </p>
              <p>
                Designation:{" "}
                <span className="b-btm">{data?.WKWPRepDesignation}</span>
              </p>
              <p>
                Signature/official stamp:{" "}
                <span className="b-btm">
                  <img src={wkwkw_sig} />
                </span>
              </p>
            </div>
          </div>
          <h4>
            Note: Signed copies of this form must be kept in a file at the
            respective offices with an accompanying excel template that collates
            the quarterly numbers for the purposes of periodic reporting
          </h4>
        </div>
      </div>
    </div>
  );
}

const CheckBox = (props) => {
  return (
    <>
      {props.checked ? (
        <div>
          <input type="checkbox" checked />{" "}
          <label htmlFor={props.label}>{props.label}</label>
        </div>
      ) : (
        <div>
          <input type="checkbox" disabled />{" "}
          <label htmlFor={props.label}>{props.label}</label>
        </div>
      )}
    </>
  );
};

const Items1 = (props) => {
  return (
    <div className="item">
      <div className="left">
        <CheckBox label={props.label} checked={props.checked} />
      </div>
      <div className="right">
        <div>
          <p>
            {props.itm} <span className="b-btm">{props.itmVal}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const Items3 = (props) => {
  return (
    <div className="item">
      <div className="left">
        <CheckBox label={props.label} checked={props.checked} />
      </div>
      <div className="right">
        <div className={props.class}>
          <p>
            {props.itm1} <span className="b-btm">{props.itm1Val}</span>
          </p>
          <p>
            {props.itm2} <span className="b-btm">{props.itm2Val}</span>
          </p>
          <p>
            {props.itm3} <span className="b-btm">{props.itm3Val}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
