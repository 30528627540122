import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import "../../../Styles/dataentry.scss";
import Button from "../../Util/Button";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import Select from "../../Util/Select";
import SignatureInput from "../../Util/SignatureInput";

export default function ARFDetails(props) {
  const topics = [
    "Governance",
    "Technical",
    "Financial management",
    "Service delivery",
    "Human resources - Staff behaviour",
    "Monitoring systems",
    "Project planning and management of implementation",
    "Financial management",
    "Regulatory compliance/Enforcement of policies",
    "Adherence to stakeholder mandates",
    "Equity/Client orientation",
    "Adequacy for the context",
  ];

  const subTopics = [
    "Training of management committees on leadership and management",
    "Awareness creation on water sector reforms and their effect on water service provision",
    "Training of the management committees on operation and maintenance of the water facilities",
    "Training of rural water management committees on financial management",
    "Training of the rural water enterprises on customer care",
    "WRM advocacy and Stakeholder engagement",
    "Groundwater and surface data collection and management methodologies",
    "WRUA Organizational Governance and Leadership",
    "Financial Management and Resource Mobilization",
    "Training on PES",
    "Riparian and Catchment restoration and conservation",
    "Agricultural best management practices",
    "Water Resource protection and pollution control",
    "Climate change mitigation ",
    "Water infrastructure development ",
    "Alternative Income Generating Activities",
  ];

  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState("");
  const [countyList, setCountyList] = useState([]);
  const [subcountyList, setSubCountyList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [selCounty, setSelCounty] = useState(null);
  const [selSubCounty, setSelSubCounty] = useState(null);
  const [rep, setRep] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [body, setBody] = useState({
    ActivityName: null,
    ActivityOrganizer: null,
    County: null,
    SubCounty: null,
    Ward: null,
    Village: null,
    Longitude: null,
    Latitude: null,
    FacilitatorName: null,
    FacilitatorContact: null,
    FacilitatorOrganisation: null,
    ActivityType: null,
    ActivitySector: null,
    TrainingTopics: null,
    TrainingSubTopics: null,
    ActivityDescription: null,
    Date: null,
    NumberOfYouths: null,
    AdultsABove30: null,
    MaleAtendees: null,
    FemaleAtendees: null,
    PWDAtendees: null,
    ERepName: null,
    ERepDesignation: null,
    WKWPRepName: null,
    WKWPRepDesignation: null,
    File: null,
    UserID: null,
  });
  const ActivityName = useRef();
  const ActivityOrganizer = useRef();
  const County = useRef();
  const SubCounty = useRef();
  const Ward = useRef();
  const Village = useRef();
  const Longitude = useRef();
  const Latitude = useRef();
  const FacilitatorName = useRef();
  const FacilitatorContact = useRef();
  const FacilitatorOrganisation = useRef();
  const ActivityType = useRef();
  const ActivitySector = useRef();
  const TrainingTopics = useRef();
  const TrainingSubTopics = useRef();
  const ActivityDescription = useRef();
  const date = useRef();
  const NumberOfYouths = useRef();
  const AdultsABove30 = useRef();
  const MaleAtendees = useRef();
  const FemaleAtendees = useRef();
  const PWDAtendees = useRef();
  const ERepName = useRef();
  const ERepDesignation = useRef();
  const ERepSignature = useRef();
  const WKWPRepSignature = useRef();
  const myFile = useRef();

  useEffect(() => {
    fetch("/api/counties/uniquecounties")
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setCountyList(data);
        if (data.length > 0) {
          setSelCounty(data[0]);
        }
      })
      .catch((e) => {});
    fetch(`/api/auth`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (selCounty) {
      fetch(`/api/counties/getsubcounties/${selCounty}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setSubCountyList(data);
          if (data.length > 0) setSelSubCounty(data[0]);
        })
        .catch((e) => {});
    }
  }, [selCounty]);

  useEffect(() => {
    if (selSubCounty) {
      fetch(`/api/counties/getwards/${selSubCounty}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWardList(data);
        })
        .catch((e) => {});
    }
  }, [selSubCounty]);

  const createActivity = async (e) => {
    setError("");
    let d = body;
    d.ActivityName = ActivityName.current.value;
    d.ActivityOrganizer = ActivityOrganizer.current.value;
    d.County = County.current.value;
    d.SubCounty = SubCounty.current.value;
    d.Ward = Ward.current.value;
    d.Village = Village.current.value;
    d.Date = date.current.value;
    d.Longitude = Longitude.current.value;
    d.Latitude = Latitude.current.value;
    d.FacilitatorName = FacilitatorName.current.value;
    d.FacilitatorContact = FacilitatorContact.current.value;
    d.FacilitatorOrganisation = FacilitatorOrganisation.current.value;
    d.ActivityType = ActivityType.current.value;
    d.ActivitySector = ActivitySector.current.value;
    d.TrainingTopics = TrainingTopics.current.value;
    d.TrainingSubTopics = TrainingSubTopics.current.value;
    d.ActivityDescription = ActivityDescription.current.value;
    d.NumberOfYouths = NumberOfYouths.current.value;
    d.AdultsABove30 = AdultsABove30.current.value;
    d.MaleAtendees = MaleAtendees.current.value;
    d.FemaleAtendees = FemaleAtendees.current.value;
    d.PWDAtendees = PWDAtendees.current.value;
    d.ERepName = ERepName.current.value;
    d.ERepDesignation = ERepDesignation.current.value;
    d.WKWPRepName = selectedRep ? selectedRep.Name : null;
    d.WKWPRepDesignation = selectedRep ? selectedRep.Position : null;
    if (myFile.current.files.length > 0) {
      d.File = myFile.current.files.length > 0 ? myFile.current.files[0] : null;

      d.File = await convertFileToBase64(d.File);
    }
    d.ERepSignature = ERepSignature.current.toDataURL();
    d.WKWPRepSignature = WKWPRepSignature.current.toDataURL();
    d.UserID = selectedRep ? selectedRep.UserID : null;

    setBody(d);

    const cols = Object.keys(d);

    const keysToExclude = ["File"];
    const chck = Object.keys(d)
      .filter((key) => !keysToExclude.includes(key))
      .map((key) => d[key]);
    let valid = true;
    chck.map((item, i) => {
      if (item === null || item === "" || item === "Select") {
        valid = false;
        if (!valid) return setError(`${cols[i]} is required!`);
      }
    });

    setLoading(true);

    fetch("/api/arf/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          if (data.ID) {
            setTimeout(() => {
              window.location.href = "/dataentry/arf-list";
            }, 3000);
          }
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  function getFile() {
    setLoading(true);
    fetch(`/api/uploads/${data.File}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then((data) => {
        const imageURL = URL.createObjectURL(data);
        window.open(imageURL, "_blank");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="taf">
      <div>
        <Input
          ref={ActivityName}
          value={props?.data?.ActivityName}
          label="Activity Name *"
        />
        <Input
          ref={ActivityOrganizer}
          value={props?.data?.ActivityOrganizer}
          label="Activity Organizer/Convener *"
        />

        <div className="div2equal">
          <Select
            ref={County}
            data={countyList}
            value={props?.data?.County}
            label="County *"
            setChanged={(e) => {
              setSelCounty(e);
            }}
          />
          <Select
            ref={SubCounty}
            value={props?.data?.SubCounty}
            label="Sub-County *"
            setChanged={(e) => {
              setSelSubCounty(e);
            }}
            data={subcountyList}
          />
        </div>
        <div className="div2equal">
          <Select
            ref={Ward}
            value={props?.data?.Ward}
            label="Ward *"
            data={wardList}
          />
          <Input
            ref={Village}
            value={props?.data?.Village}
            type="text"
            label="Village/Town *"
          />
        </div>
        <div className="div2equal">
          <Input
            ref={Latitude}
            value={props?.data?.Latitude}
            type="number"
            label="Latitude (Use negative for values South of equator) *"
          />
          <Input
            ref={Longitude}
            value={props?.data?.Longitude}
            type="number"
            label="Longitude *"
          />
        </div>
        <div className="div3equal">
          <Input
            ref={FacilitatorName}
            value={props?.data?.FacilitatorName}
            type="text"
            label="Name of Facilitator *"
          />
          <Input
            ref={FacilitatorContact}
            value={props?.data?.FacilitatorContact}
            type="number"
            label="Facilitator Phone *"
          />
          <Input
            ref={FacilitatorOrganisation}
            value={props?.data?.FacilitatorOrganisation}
            type="text"
            label="Organization *"
          />
        </div>
        <div className="div2equal">
          <Select
            ref={ActivityType}
            value={props?.data?.ActivityType}
            label="Activity Type *"
            data={[
              "",
              "Training",
              "Workshop",
              "Meeting",
              "Technical Assistance",
              "Other",
            ]}
          />
          <Input
            ref={date}
            value={new Date().toISOString().split("T")[0]}
            type="date"
            label="Date *"
          />
        </div>

        <Input
          ref={ActivitySector}
          value={props?.data?.ActivitySector}
          label="Activity Sector *"
        />

        <h4>If Activity Type is Training ... </h4>
        <hr />
        <Select
          ref={TrainingTopics}
          value={props?.data?.ActivityType}
          label="Training Topic Covered (optional) *"
          data={topics}
        />
        <Select
          ref={TrainingSubTopics}
          value={props?.data?.ActivityType}
          label="Sub Topics Covered (optional) *"
          data={subTopics}
        />
        <hr />
        <br />
        <Input
          ref={ActivityDescription}
          value={props?.data?.ActivityDescription}
          type="textarea"
          label="Brief Description of Activity Covered *"
        />
        <h4>Activity Participants...</h4>
        <hr />
        <div className="div2equal">
          <Input
            ref={NumberOfYouths}
            type="number"
            label="Number of Youths"
            placeholder="Number of youths"
          />
          <Input
            ref={AdultsABove30}
            type="number"
            label="Number of Adults above 30"
            placeholder="Number of Adults above 30"
          />
        </div>
        <div className="div3equal">
          <Input
            ref={MaleAtendees}
            type="number"
            label="Number male atendees"
            placeholder="Number male atendees"
          />
          <Input
            ref={FemaleAtendees}
            type="number"
            label="Number of female atendees"
            placeholder="Number of female atendees"
          />
          <Input
            ref={PWDAtendees}
            type="number"
            label="Number of PWD atendees"
            placeholder="Number of PWD atendees"
          />
        </div>
        <h3>Sign Off - WKWP Representative</h3>
        <div className="div2equal">
          <div className="search">
            <Select
              setChanged={(v) => {
                let d = data.filter((obj) => obj.Name === v);
                if (d.length > 0) {
                  setSelectedRep(d[0]);
                }
                console.log(selectedRep);
              }}
              data={data.map((obj) => obj.Name)}
              label="Select WKWP Representative"
            />
            {selectedRep && (
              <div>
                <h4>
                  <b>Name:</b> {selectedRep.Name}
                </h4>
                <h4>
                  <b>Designation:</b> {selectedRep.Position}
                </h4>
              </div>
            )}
          </div>
          <SignatureInput
            ref={WKWPRepSignature}
            value={data?.WKWPRepSignature}
            label="WKWP Represenative"
          />
        </div>

        <h3>Sign Off - Entity Representative</h3>
        <div className="div2equal">
          <div>
            <Input
              value={data?.ERepName}
              ref={ERepName}
              type="text"
              label="Name *"
            />
            <br />
            <Input
              value={data?.ERepDesignation}
              ref={ERepDesignation}
              type="text"
              label="Designation *"
            />
          </div>
          <SignatureInput
            ref={ERepSignature}
            value={data?.ERepSignature}
            label="Entity Represenative Signature"
          />
        </div>

        <h3>Upload Scanned ARF (optional)</h3>
        {data?.File != null && (
          <h4
            onClick={() => {
              getFile();
            }}
            className="view"
          >
            View uploaded file
          </h4>
        )}
        <div className="file-input">
          <input ref={myFile} type="file" accept=".pdf" />
          <FontAwesomeIcon onClick={() => {}} icon={faTrash} className="ic" />
        </div>

        <h6>{error}</h6>
        <Button handleClick={createActivity} value="Submit" />
        <br />
        {loading && <Loading />}
      </div>
    </div>
  );
}
