import {
  faPencilAlt,
  faTimes,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import Confirm from "../Util/Confirm";
import ConfirmSuccess from "../Util/ConfirmSuccess";

export default function SelectedBeneficiary(props) {
  const [clicked, setClicked] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleperformance, setTogglePerformance] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [kpis, setKPIs] = useState(null);
  const [active, setActive] = useState("Org Details");
  const [koffset, setKOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (props?.beneficiaryDetails && active === "Org Details") {
      props.setLoading(true);
      fetch(
        `/api/providerperfomance/stakeholder/${props?.beneficiaryDetails?.ID}/${offset}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          props.setLoading(false);
          setData(data);
        })
        .catch((error) => {
          props.setLoading(false);
        });
    }
  }, [props?.beneficiaryDetails, refresh, active, offset]);

  useEffect(() => {
    if (props?.beneficiaryDetails && active === "WASREB KPIs") {
      props.setLoading(true);
      fetch(
        `/api/indicatorsperformance/stakeholder/${props?.beneficiaryDetails?.ID}/${koffset}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          props.setLoading(false);
          setKPIs(data);
        })
        .catch((error) => {
          props.setLoading(false);
        });
    }
  }, [props?.beneficiaryDetails, refresh, active, koffset]);

  function deleteBeneficiary() {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.beneficiaryDetails?.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
        setTimeout(() => {
          props.setRefresh(!props.refresh);
        }, 5000);
        setShowSuccess(true);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  function deleteItem(url, id) {
    props.setLoading(true);
    fetch(`/api/${url}/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  const Item = (params) => {
    return (
      <div className="itt">
        <p className="date">Date: {params?.item?.Date}</p>
        <div className="div2equal">
          <p>Regulated: {params?.item?.Regulation}</p>
          <p>SDM: {params?.item?.SDM}</p>
          <p>DS Rehabilitated: {params?.item?.DrinkingSystems_Rehabilitated}</p>
        </div>

        <div className="div4equal">
          <p>Staff M: {params?.item?.No_StaffMale}</p>
          <p>Staff F: {params?.item?.No_StaffFemale}</p>
          <p>BOD M: {params?.item?.BoardMembers_Male}</p>
          <p>BOD F: {params?.item?.BoardMembers_Female}</p>
        </div>
        <div className="div4equal">
          <p>BOD Trained M: {params?.item?.Training_BoardMembers_Male}</p>
          <p>BOD Trained F: {params?.item?.Training_BoardMembers_Female}</p>
          <p>Resources Leveraged: {params?.item?.ResourcesLeveraged}</p>
        </div>
        <FontAwesomeIcon
          className="trash"
          onClick={() => {
            deleteItem("providerperfomance", params?.item?.ID);
          }}
          icon={faTrash}
        />
      </div>
    );
  };

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const openSucess = () => {
    setShowSuccess(true);
  };

  const closeSuccess = () => {
    setShowSuccess(false);
  };

  const KPI = (params) => {
    return (
      <div className="itt">
        <p className="date">Date: {params?.item?.Date}</p>
        <div className="div2equal">
          <p>
            NRW: {params?.item?.NRW_Percentage}% -{" "}
            {params?.item?.NRW_Percentage_Baseline}%
          </p>
          <p>
            Supply Hours: {params?.item?.SupplyHours} Hrs -{" "}
            {params?.item?.SupplyHours_Baseline} Hrs
          </p>
        </div>
        <div className="div2equal">
          <p>
            Cost Coverage: {params?.item?.CostCoverage}% -{" "}
            {params?.item?.CostCoverage_Baseline}%
          </p>
          <p>
            Revenue Collection Efficiency:{" "}
            {params?.item?.RevenueCollection_Efficiency}% -{" "}
            {params?.item?.RevenueCollection_Efficiency_Baseline}%
          </p>
        </div>
        <div className="div2equal">
          <p>
            Water Coverage: {params?.item?.WaterCoverage_Percentage}% -{" "}
            {params?.item?.WaterCoverage_Percentage_Baseline}%
          </p>
          <p>
            DQW Percentage: {params?.item?.DWQ_Percentage}% -{" "}
            {params?.item?.DWQ_Percentage_Baseline}%
          </p>
        </div>
        <div className="div2equal">
          <p>
            Staff Productivity: {params?.item?.StaffProductivity} -{" "}
            {params?.item?.StaffProductivity_Baseline}
          </p>
          <p>
            Personnel Expenditure: {params?.item?.PersonnelExpenditure} -{" "}
            {params?.item?.PersonnelExpenditure_Baseline}
          </p>
        </div>
        <p>
          Metering Ratio: {params?.item?.MeteringRatio} -{" "}
          {params?.item?.MeteringRatio_Baseline}
        </p>
        <FontAwesomeIcon
          className="trash"
          onClick={() => {
            deleteItem("indicatorsperformance", params?.item?.ID);
          }}
          icon={faTrash}
        />
      </div>
    );
  };

  return (
    <div className="sbeneficiary">
      <div>
        <p>Name: {props?.beneficiaryDetails?.Name}</p>
        <p>Type: {props?.beneficiaryDetails?.Type}</p>
        <p>County: {props?.beneficiaryDetails?.County}</p>
        <p>SubCounty: {props?.beneficiaryDetails?.SubCounty}</p>
        <p>Ward: {props?.beneficiaryDetails?.Ward}</p>
        {props?.userDetails?.Village && (
          <p>Village: {props?.userDetails?.Village}</p>
        )}
        <br />
        <h4>Contact Person</h4>
        <hr />
        <p>Name: {props?.beneficiaryDetails?.ContactName}</p>
        <p>Phone: {props?.beneficiaryDetails?.ContactPhone}</p>
        <p>Designation: {props?.beneficiaryDetails?.ContactDesignation}</p>
      </div>

      <div className="data">
        <div className="bars">
          <h4
            onClick={() => {
              setActive("Org Details");
            }}
            className={active === "Org Details" ? "active" : ""}
          >
            Org Details
          </h4>
          <h4
            onClick={() => {
              setActive("WASREB KPIs");
            }}
            className={active === "WASREB KPIs" ? "active" : ""}
          >
            WASREB KPIs
          </h4>
          <h4
            onClick={() => {
              setActive("Update Details");
              setToggleBasic(true);
            }}
            className={active === "Update Details" ? "active" : ""}
          >
            Update Basic
          </h4>
        </div>

        <hr />
        {active === "Org Details" &&
          data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return <Item key={i} item={item} />;
          })}
        {active === "WASREB KPIs" &&
          kpis &&
          kpis?.data?.length > 0 &&
          kpis?.data?.map((item, i) => {
            return <KPI key={i} item={item} />;
          })}
        {active === "WASREB KPIs" && kpis && (
          <Pagination
            totalItems={kpis?.total}
            currentPage={koffset}
            onPageChange={(v) => {
              setKOffset(v);
            }}
          />
        )}
        {active === "Org Details" && data && (
          <Pagination
            totalItems={data?.total}
            currentPage={offset}
            onPageChange={(v) => {
              setOffset(v);
            }}
          />
        )}
      </div>

      <div className="actions">
        <h6
          onClick={() => {
            setClicked(true);
          }}
        >
          <FontAwesomeIcon className="fa" icon={faPencilAlt} />
          Org Details
        </h6>
        <h6
          onClick={() => {
            setTogglePerformance(true);
          }}
        >
          <FontAwesomeIcon className="fa" icon={faPencilAlt} />
          KPIs
        </h6>
        <h6
          onClick={() => {
            openConfirm();
          }}
        >
          <FontAwesomeIcon className="fa" icon={faTrash} />
          Delete
        </h6>
      </div>
      {toggleperformance && (
        <UpdateKPIs
          setTogglePerformance={setTogglePerformance}
          setRefresh={setRefresh}
          refresh={refresh}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
      {clicked && (
        <UpdateOrgDetails
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
      {toggleBasic && (
        <UpdateBasicDetails
          setToggleBasic={setToggleBasic}
          setRefresh={setRefresh}
          refresh={refresh}
          beneficiaryDetails={props.beneficiaryDetails}
        />
      )}
      {showConfirm && (
        <Confirm
          closeConfirm={closeConfirm}
          deleteFunction={deleteBeneficiary}
          message="you want to delete?"
          action="delete"
        />
      )}
      {showSuccess && (
        <ConfirmSuccess closeConfirm={closeSuccess} action="deleted" />
      )}
    </div>
  );
}

const UpdateOrgDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const date = useRef();
  const regulation = useRef();
  const sdm = useRef();
  const rehabilitation = useRef();
  const maleStaff = useRef();
  const femaleStaff = useRef();
  const femaleBoardTrained = useRef();
  const maleBoardTrained = useRef();
  const femaleBoard = useRef();
  const maleBoard = useRef();
  const resourcesLeveraged = useRef();

  const createBeneficiary = () => {
    const body = {
      StakeholderID: props.beneficiaryDetails?.ID,
      Date: date.current.value,
      Regulation: regulation.current.value,
      SDM: sdm.current.value,
      DrinkingSystems_Rehabilitated: rehabilitation.current.value,
      No_StaffMale: maleStaff.current.value,
      No_StaffFemale: femaleStaff.current.value,
      Training_BoardMembers_FemaleFemale: femaleBoardTrained.current.value,
      Training_BoardMembers_Male: maleBoardTrained.current.value,
      BoardMembers_Female: femaleBoard.current.value,
      BoardMembers_Male: maleBoard.current.value,
      ResourcesLeveraged: resourcesLeveraged.current.value,
    };

    if (body.Date === "" || body.Date === null) {
      return setError("Date cannot be null...");
    }

    setError("");
    setLoading(true);
    fetch(`/api/providerperfomance/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.beneficiaryDetails?.Name}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input ref={date} type="date" label="Date*" />
            <Input
              ref={rehabilitation}
              value={
                props?.beneficiaryDetails?.DrinkingSystems_Rehabilitated
                  ? props?.beneficiaryDetails?.DrinkingSystems_Rehabilitated
                  : 0
              }
              type="number"
              label="Number Drinking Water Systems Rehabilitated"
            />
            <Select
              ref={regulation}
              data={[" ", "YES", "NO"]}
              label="Is the WSP/RWE Regulated?"
            />
            <Select
              ref={sdm}
              value={props?.beneficiaryDetails?.SDM}
              data={[" ", "YES", "NO"]}
              label="Does the WSP/RWE have an SDM?"
            />
            <Input
              ref={maleStaff}
              value={
                props?.beneficiaryDetails?.No_StaffMale
                  ? props?.beneficiaryDetails?.No_StaffMale
                  : 0
              }
              type="number"
              label="Number of Male Staff"
            />
            <Input
              ref={femaleStaff}
              value={
                props?.beneficiaryDetails?.No_StaffFemale
                  ? props?.beneficiaryDetails?.No_StaffFemale
                  : 0
              }
              type="number"
              label="Number of Female Staff"
            />
            <Input
              ref={maleBoard}
              value={
                props?.beneficiaryDetails?.BoardMembers_Male
                  ? props?.beneficiaryDetails?.BoardMembers_Male
                  : 0
              }
              type="number"
              label="Number of Male Board Members"
            />
            <Input
              ref={femaleBoard}
              value={
                props?.beneficiaryDetails?.BoardMembers_Female
                  ? props?.beneficiaryDetails?.BoardMembers_Female
                  : 0
              }
              type="number"
              label="Number of Female Board Members"
            />
            <Input
              ref={maleBoardTrained}
              value={
                props?.beneficiaryDetails?.Training_BoardMembers_Male
                  ? props?.beneficiaryDetails?.Training_BoardMembers_Male
                  : 0
              }
              type="number"
              label="Number of Male Board Members Trained"
            />
            <Input
              ref={femaleBoardTrained}
              value={
                props?.beneficiaryDetails?.Training_BoardMembers_Female
                  ? props?.beneficiaryDetails?.Training_BoardMembers_Female
                  : 0
              }
              type="number"
              label="Number of Female Board Members Trained"
            />
            <Input
              ref={resourcesLeveraged}
              value={
                props?.beneficiaryDetails?.ResourcesLeveraged
                  ? props?.beneficiaryDetails?.ResourcesLeveraged
                  : 0
              }
              type="number"
              label="Resources Leveraged"
            />
            <p>{error}</p>
            <Button handleClick={createBeneficiary} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

const UpdateKPIs = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [active, setActive] = useState("WSP Performance");
  const [baselines, setBaselines] = useState(0);
  const stakeholderID = props?.beneficiaryDetails?.ID;
  const date = useRef();
  const DWQ_percentage = useRef();
  const NRW_percentage = useRef();
  const waterCoverage_percentage = useRef();
  const supplyHours = useRef();
  const staffProductivity = useRef();
  const revenueCollection_Efficiency = useRef();
  const personnelExpenditure = useRef();
  const costCoverage = useRef();
  const meteringRatio = useRef();
  const DWQ_percentage_B = useRef();
  const NRW_percentage_B = useRef();
  const waterCoverage_percentage_B = useRef();
  const supplyHours_B = useRef();
  const staffProductivity_B = useRef();
  const revenueCollection_Efficiency_B = useRef();
  const personnelExpenditure_B = useRef();
  const costCoverage_B = useRef();
  const meteringRatio_B = useRef();

  const AddPerformance = () => {
    let body = {
      StakeholderID: stakeholderID,
      Date: date.current.value,
      DWQ_Percentage_Baseline: DWQ_percentage_B.current.value,
      NRW_Percentage_Baseline: NRW_percentage_B.current.value,
      WaterCoverage_Percentage_Baseline:
        waterCoverage_percentage_B.current.value,
      SupplyHours_Baseline: supplyHours_B.current.value,
      StaffProductivity_Baseline: staffProductivity_B.current.value,
      RevenueCollection_Efficiency_Baseline:
        revenueCollection_Efficiency_B.current.value,
      PersonnelExpenditure_Baseline: personnelExpenditure_B.current.value,
      CostCoverage_Baseline: costCoverage_B.current.value,
      MeteringRatio_Baseline: meteringRatio_B.current.value,
      DWQ_Percentage: DWQ_percentage.current.value,
      NRW_Percentage: NRW_percentage.current.value,
      WaterCoverage_Percentage: waterCoverage_percentage.current.value,
      SupplyHours: supplyHours.current.value,
      StaffProductivity: staffProductivity.current.value,
      RevenueCollection_Efficiency: revenueCollection_Efficiency.current.value,
      PersonnelExpenditure: personnelExpenditure.current.value,
      CostCoverage: costCoverage.current.value,
      MeteringRatio: meteringRatio.current.value,
    };

    let d = {};
    let keys = Object.keys(body);
    let values = Object.values(body);

    values.map((v, i) => {
      if (v !== "" || v !== null) {
        d[keys[i]] = v;
      }
    });

    if (d?.Date === "") {
      return setError("Date is required!");
    }

    setLoading(true);
    fetch(`/api/indicatorsperformance/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setTogglePerformance(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchBaselines = () => {
    fetch(`/api/indicatorsperformance/baselines/${stakeholderID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setBaselines(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchBaselines();
  }, [stakeholderID]);

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.beneficiaryDetails?.Name}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setTogglePerformance(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input ref={date} type="date" label="Date*" />

            {props?.beneficiaryDetails?.Type === "WSP" ? (
              <>
                <div className="div1auto">
                  <Input
                    ref={DWQ_percentage}
                    type="number"
                    label="DWQ Percentage"
                    value={0}
                  />
                  <Input
                    ref={DWQ_percentage_B}
                    value={
                      baselines?.DWQ_Percentage_Baseline
                        ? baselines?.DWQ_Percentage_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>

                <div className="div1auto">
                  <Input
                    ref={NRW_percentage}
                    type="number"
                    label="NRW Percentage"
                    value={0}
                  />
                  <Input
                    ref={NRW_percentage_B}
                    value={
                      baselines?.NRW_Percentage_Baseline
                        ? baselines?.NRW_Percentage_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>

                <div className="div1auto">
                  <Input
                    ref={waterCoverage_percentage}
                    type="number"
                    label="Water Coverage Percentage"
                    value={0}
                  />
                  <Input
                    ref={waterCoverage_percentage_B}
                    value={
                      baselines?.WaterCoverage_Percentage_Baseline
                        ? baselines?.WaterCoverage_Percentage_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
                <div className="div1auto">
                  <Input
                    ref={supplyHours}
                    type="number"
                    label="Supply Hours"
                    value={0}
                  />
                  <Input
                    ref={supplyHours_B}
                    value={
                      baselines?.SupplyHours_Baseline
                        ? baselines?.SupplyHours_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>

                <div className="div1auto">
                  <Input
                    ref={staffProductivity}
                    type="number"
                    label="Staff Productivity"
                    value={0}
                  />{" "}
                  <Input
                    ref={staffProductivity_B}
                    value={
                      baselines?.StaffProductivity_Baseline
                        ? baselines?.StaffProductivity_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>

                <div className="div1auto">
                  <Input
                    ref={revenueCollection_Efficiency}
                    type="number"
                    label="Revenue Collection Efficiency"
                    value={0}
                  />
                  <Input
                    ref={revenueCollection_Efficiency_B}
                    value={
                      baselines?.RevenueCollection_Efficiency_Baseline
                        ? baselines?.RevenueCollection_Efficiency_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>

                <div className="div1auto">
                  <Input
                    ref={personnelExpenditure}
                    type="number"
                    label="Personnel Expenditure"
                    value={0}
                  />
                  <Input
                    ref={personnelExpenditure_B}
                    value={
                      baselines?.PersonnelExpenditure_Baseline
                        ? baselines?.PersonnelExpenditure_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
                <div className="div1auto">
                  <Input
                    ref={costCoverage}
                    type="number"
                    label="Cost Coverage"
                    value={0}
                  />
                  <Input
                    ref={costCoverage_B}
                    value={
                      baselines?.CostCoverage_Baseline
                        ? baselines?.CostCoverage_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
                <div className="div1auto">
                  <Input
                    ref={meteringRatio}
                    type="number"
                    label="Metering Ratio"
                    value={0}
                  />
                  <Input
                    ref={meteringRatio_B}
                    value={
                      baselines?.MeteringRatio_Baseline
                        ? baselines?.MeteringRatio_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="div1auto">
                  <Input
                    ref={waterCoverage_percentage}
                    type="number"
                    label="Water Coverage Percentage"
                    value={0}
                  />
                  <Input
                    ref={waterCoverage_percentage_B}
                    value={
                      baselines?.WaterCoverage_Percentage_Baseline
                        ? baselines?.WaterCoverage_Percentage_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
                <div className="div1auto">
                  <Input
                    ref={DWQ_percentage}
                    type="number"
                    label="DWQ Percentage"
                    value={0}
                  />
                  <Input
                    ref={DWQ_percentage_B}
                    value={
                      baselines?.DWQ_Percentage_Baseline
                        ? baselines?.DWQ_Percentage_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
                <div className="div1auto">
                  <Input
                    ref={NRW_percentage}
                    type="number"
                    label="NRW Percentage"
                    value={0}
                  />
                  <Input
                    ref={NRW_percentage_B}
                    value={
                      baselines?.NRW_Percentage_Baseline
                        ? baselines?.NRW_Percentage_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
                <div className="div1auto">
                  <Input
                    ref={revenueCollection_Efficiency}
                    type="number"
                    label="Revenue Collection Efficiency"
                    value={0}
                  />
                  <Input
                    ref={revenueCollection_Efficiency_B}
                    value={
                      baselines?.RevenueCollection_Efficiency_Baseline
                        ? baselines?.RevenueCollection_Efficiency_Baseline
                        : 0
                    }
                    type="number"
                    label="Baseline"
                  />
                </div>
              </>
            )}

            <p>{error}</p>
            <Button handleClick={AddPerformance} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

const UpdateBasicDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const type = useRef();
  const county = useRef();
  const subCounty = useRef();
  const ward = useRef();
  const village = useRef();
  const name = useRef();
  const contactPerson = useRef();
  const contactPhone = useRef();
  const contactDesignation = useRef();
  const latitude = useRef();
  const longitude = useRef();

  const updateBasicDetails = () => {
    const body = {
      Type: type.current.value,
      County: county.current.value,
      SubCounty: subCounty.current.value,
      Ward: ward.current.value,
      Village: village.current.value,
      Name: name.current.value,
      ContactName: contactPerson.current.value,
      ContactPhone: contactPhone.current.value,
      ContactDesignation: contactDesignation.current.value,
      Latitude: latitude.current.value,
      Longitude: longitude.current.value,
    };

    setError("");
    setLoading(true);
    fetch(`/api/waterproviders/update/${props.beneficiaryDetails.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setToggleBasic(false);
            window.location.href = "/mel";
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.beneficiaryDetails?.Name}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setToggleBasic(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <Input
                ref={name}
                value={props?.beneficiaryDetails?.Name}
                type="text"
                label="Name of Beneficiary"
              />
              <Select
                ref={type}
                value={props?.beneficiaryDetails?.Type}
                data={["WSP", "RWE", "County", "WRUA", "CSO", "Other"]}
                label="Select Beneficiary Type"
              />
            </div>
            <div className="div2equal">
              <Select
                ref={county}
                value={props?.beneficiaryDetails?.County}
                data={[
                  " ",
                  "Bungoma",
                  "Busia",
                  "Homabay",
                  "Kakamega",
                  "Kisii",
                  "Kisumu",
                  "Migori",
                  "Siaya",
                ]}
                label="Select the County of the Beneficiary"
              />
              <Input
                ref={subCounty}
                value={props?.beneficiaryDetails?.SubCounty}
                type="text"
                label="Enter the SubCounty of the Beneficiary"
              />
            </div>
            <div className="div2equal">
              <Input
                ref={ward}
                value={props?.beneficiaryDetails?.Ward}
                type="text"
                label="Enter the Ward of the Beneficiary"
              />
              <Input
                ref={village}
                value={props?.beneficiaryDetails?.Village}
                type="text"
                label="Enter the Village of the Beneficiary"
              />
            </div>
            <Input
              ref={contactPerson}
              value={props?.beneficiaryDetails?.ContactName}
              type="text"
              label="Name of Contact Person"
            />
            <Input
              ref={contactPhone}
              value={props?.beneficiaryDetails?.ContactPhone}
              type="number"
              label="Phone number of Contact Person"
            />
            <Input
              ref={contactDesignation}
              value={props?.beneficiaryDetails?.ContactDesignation}
              type="text"
              label="Designation of Contact Person"
            />
            <Input
              ref={latitude}
              value={props?.beneficiaryDetails?.Latitude}
              type="number"
              label="Latitude (Start with negative (-) sign if it is Southern Hemisphere)"
            />
            <Input
              ref={longitude}
              value={props?.beneficiaryDetails?.Longitude}
              type="number"
              label="Longitude"
            />
            <p>{error}</p>
            <Button handleClick={updateBasicDetails} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
