import {
  faArrowRight,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import "../../../Styles/dataentry.scss";
import Button from "../../Util/Button";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import MultiSelect from "../../Util/MultiSelect";
import Select from "../../Util/Select";
import SignatureInput from "../../Util/SignatureInput";

export default function RMFNew(props) {
  const [active, setActive] = useState("Basic Details");
  const [editing, setEditing] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const e = localStorage.getItem("editing");
    setEditing(e);
  }, [active]);

  useEffect(() => {
    if (editing !== null) {
      fetch(`/api/rmf/${editing}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setData(data);
        })
        .catch((e) => {});
    }
  }, [editing, active]);

  return (
    <div className="dataentry">
      <div className="new">
        <div className="topbar">
          <h4>New Result Monitoring Form</h4>
          <p
            onClick={() => {
              window.location.href = "/dataentry/rmf-list";
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} /> List
          </p>
        </div>
        <div className="rmf">
          <div className="bar">
            <Item txt="Basic Details" active={active} setActive={setActive} />
            <Item
              txt="Rural and Urban Water Services"
              active={active}
              setActive={setActive}
            />
            <Item
              txt="Water Resource Management"
              active={active}
              setActive={setActive}
            />
            <Item
              txt="Finance and Private Sector Engagement"
              active={active}
              setActive={setActive}
            />
            <Item
              txt="Policy and Governance"
              active={active}
              setActive={setActive}
            />
            <Item txt="Sign Off" active={active} setActive={setActive} />
          </div>
          {active === "Basic Details" && (
            <Step1 data={data} setActive={setActive} editing={editing} />
          )}
          {active === "Rural and Urban Water Services" && (
            <>
              {editing !== null ? (
                <Step2 data={data} setActive={setActive} editing={editing} />
              ) : (
                <Error />
              )}
            </>
          )}
          {active === "Water Resource Management" && (
            <>
              {editing !== null ? (
                <Step3 data={data} setActive={setActive} editing={editing} />
              ) : (
                <Error />
              )}
            </>
          )}
          {active === "Finance and Private Sector Engagement" && (
            <>
              {editing !== null ? (
                <Step4 data={data} setActive={setActive} editing={editing} />
              ) : (
                <Error />
              )}
            </>
          )}
          {active === "Policy and Governance" && (
            <>
              {editing !== null ? (
                <Step5 data={data} setActive={setActive} editing={editing} />
              ) : (
                <Error />
              )}
            </>
          )}
          {active === "Sign Off" && (
            <>
              {editing !== null ? (
                <Step6 data={data} setActive={setActive} editing={editing} />
              ) : (
                <Error />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className="item"
    >
      <p className={props.txt === props.active ? "active" : ""}>{props.txt}</p>
    </div>
  );
};

const Step1 = (props) => {
  const TATypes = [
    { value: "Operational", label: "Operational" },
    { value: "Management", label: "Management" },
    { value: "Engineering", label: "Engineering" },
    { value: "Capacity Building", label: "Capacity Building" },
    { value: "Finance", label: "Finance" },
    { value: "Data & Digital Solutions", label: "Data & Digital Solutions" },
  ];
  const TAThroughList = [
    { value: "Training", label: "Training" },
    { value: "Grant", label: "Grant" },
    {
      value: "Meeting/Coaching/Mentoring",
      label: "Meeting/Coaching/Mentoring",
    },
    { value: "Other", label: "Other" },
  ];
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [stakeholderList, SetStakeholderList] = useState();
  const [selectedStakeholder, setSelectedStakeholder] = useState();
  const [countyList, setCountyList] = useState([]);
  const [subcountyList, setSubCountyList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [selCounty, setSelCounty] = useState(null);
  const [selSubCounty, setSelSubCounty] = useState(null);
  const [TATypeOptions, setTATypeOptions] = useState("");
  const [TAThroughOptions, setTAThroughOptions] = useState("");
  const [body, setBody] = useState({
    SectorStakeholder: null,
    StakeholderType: null,
    County: null,
    SubCounty: null,
    Ward: null,
    Village: null,
    Date: null,
    Longitude: null,
    Latitude: null,
    ContactName: null,
    ContactPhone: null,
    Designation: null,
    TA_Provided: null,
    TAThrough: null,
    // ChangeInSD: null,
    // Change: "N/A",
  });
  const date = useRef();
  const TA_Provided = useRef();
  const TAThrough = useRef();
  // const ChangeInSD = useRef();
  // const Change = useRef();

  const handleTATypeChange = (e) => {
    let string = e.join(", ");
    setTATypeOptions(string);
  };

  const handleTAThroughChange = (e) => {
    let string = e.join(", ");
    setTAThroughOptions(string);
  };

  const searchStakeholder = (q) => {
    fetch(`/api/waterproviders/search/${q}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        SetStakeholderList(data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetch("/api/counties/uniquecounties")
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setCountyList(data);
        if (data.length > 0) {
          setSelCounty(data[0]);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (selCounty) {
      fetch(`/api/counties/getsubcounties/${selCounty}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setSubCountyList(data);
          if (data.length > 0) setSelSubCounty(data[0]);
        })
        .catch((e) => {});
    }
  }, [selCounty]);

  useEffect(() => {
    if (selSubCounty) {
      fetch(`/api/counties/getwards/${selSubCounty}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWardList(data);
        })
        .catch((e) => {});
    }
  }, [selSubCounty]);

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.SectorStakeholder = selectedStakeholder.Name;
    d.StakeholderType = selectedStakeholder.Type;
    d.County = selectedStakeholder.County;
    d.SubCounty = selectedStakeholder.SubCounty;
    d.Ward = selectedStakeholder.Ward;
    d.Village = selectedStakeholder.Village
      ? selectedStakeholder.Village
      : "N/A";
    d.Date = date.current.value;
    d.Longitude = selectedStakeholder.Longitude;
    d.Latitude = selectedStakeholder.Latitude;
    d.ContactName = selectedStakeholder.ContactName;
    d.ContactPhone = selectedStakeholder.ContactPhone;
    d.Designation = selectedStakeholder.ContactDesignation;
    d.TA_Provided = TATypeOptions;
    d.TAThrough = TAThroughOptions;
    // d.ChangeInSD = ChangeInSD.current.value;
    // d.Change = Change.current.value;
    setBody(d);

    const cols = Object.keys(d);

    const keysToExclude = ["Change"];
    const chck = Object.keys(d)
      .filter((key) => !keysToExclude.includes(key))
      .map((key) => d[key]);
    let valid = true;
    chck.map((item, i) => {
      if (item === null || item === "" || item === "Select") {
        valid = false;
        if (!valid) return setError(`${cols[i]} is required!`);
      }
    });
    if (!valid) return;

    setLoading(true);

    fetch("/api/rmf/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          localStorage.setItem("editing", data.ID);
          setTimeout(() => {
            props.setActive("Rural and Urban Water Services");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const BenList = (params) => {
    return (
      <div
        onClick={() => {
          setSelectedStakeholder(params.item);
          SetStakeholderList(null);
        }}
        className="so_list"
      >
        <h4>{params.item.Name}</h4>
        <p>Type of Beneficiary: {params.item.Type}</p>
        <p>County: {params.item.County}</p>
        <hr height="1" />
      </div>
    );
  };

  const handleSignatureChange = (e) => {
    console.log(e);
  };

  return (
    <div className="taf">
      {props.editing === null ? (
        <div className="search">
          <Input
            type="text"
            handleChange={(e) => {
              searchStakeholder(e);
            }}
            label="Search Beneficiary *"
            hint="Type to search"
            value={selectedStakeholder?.Name && selectedStakeholder.Name}
          />
          {stakeholderList && stakeholderList?.length > 0 && (
            <div className="search_list">
              {stakeholderList?.map((item, i) => {
                return <BenList key={i} item={item} />;
              })}
            </div>
          )}
        </div>
      ) : (
        <div className="benef-details">
          <p>
            <span className="info-label">Beneficiary:</span>{" "}
            <span className="info-value">{props?.data?.SectorStakeholder}</span>
          </p>
          <p>
            <span className="info-label">BeneficiaryType:</span>{" "}
            <span className="info-value">{props?.data?.StakeholderType}</span>
          </p>
          <p>
            <span className="info-label">Latitude:</span>{" "}
            <span className="info-value">{props?.data?.Latitude}</span>
          </p>
          <p>
            <span className="info-label">Longitude:</span>{" "}
            <span className="info-value">{props?.data?.Longitude}</span>
          </p>
          <p>
            <span className="info-label">County:</span>{" "}
            <span className="info-value">{props?.data?.County}</span>
          </p>
          <p>
            <span className="info-label">SubCounty:</span>{" "}
            <span className="info-value">{props?.data?.SubCounty}</span>
          </p>
          <p>
            <span className="info-label">Ward:</span>{" "}
            <span className="info-value">{props?.data?.SubCounty}</span>
          </p>
          <p>
            <span className="info-label">Village:</span>{" "}
            <span className="info-value">{props?.data?.Village}</span>
          </p>
          <p>
            <span className="info-label">Contact's Name:</span>{" "}
            <span className="info-value">{props?.data?.ContactName}</span>
          </p>
          <p>
            <span className="info-label">Contact's Phone:</span>{" "}
            <span className="info-value">{props?.data?.ContactPhone}</span>
          </p>
          <p>
            <span className="info-label">Contact's Designation:</span>{" "}
            <span className="info-value">{props?.data?.Designation}</span>
          </p>
        </div>
      )}
      <Input
        ref={date}
        value={new Date().toISOString().split("T")[0]}
        type="date"
        label="Date *"
      />
      <div className="div2equal">
        <MultiSelect
          ref={TA_Provided}
          label="Type of TA Provided *"
          options={TATypes}
          value={props?.data?.TA_Provided}
          name="TAType"
          setChanged={handleTATypeChange}
        />
        <MultiSelect
          ref={TAThrough}
          label="TA Through *"
          value={props?.data?.TAThrough}
          options={TAThroughList}
          name="TAThrough"
          setChanged={handleTAThroughChange}
        />
      </div>
      <br />
      {/* <Select
        ref={ChangeInSD}
        value={props?.data?.ChangeInSD}
        data={[" ", "Yes", "No"]}
        label="Has there been any change in service delivery since the last Technical Assistance? *"
      /> */}
      {/* <Input
        ref={Change}
        value={props?.data?.Change}
        label="If yes, what has changed (optional)"
      /> */}

      <h6>{error}</h6>
      {props.data === null && (
        <Button handleClick={createDocument} value="Next" />
      )}
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Step2 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    RU_NRW: "",
    RU_TotalBilled: "",
    RU_TotalCollected: "",
    RU_WaterPipelineConstructed: "",
    RU_NWCIndividualConnection: "",
    RU_NWCYardTaps: "",
    RU_NWCMDU: "",
    RU_NWCKiosks: "",
    RU_Newbeneficiaries_NewConnections: "",
    RU_Beneficiaries_ImprovedService: "",
    RU_Newbeneficiaries_ProtectedSprings: "",
    RU_Beneficiaries_IS_ProtectedSprings: "",
    RU_QualityStandard: "",
    RU_WaterProduced: "",
    RU_RegistrationStatus: "",
    RU_SDM_From: "",
    RU_SDM_To: "",
    RU_WaterStorage: "",
    RU_SupplyHours: "",
    RU_New_Meters: "",
  });
  const RU_NRW = useRef();
  const RU_TotalBilled = useRef();
  const RU_TotalCollected = useRef();
  const RU_WaterPipelineConstructed = useRef();
  const RU_NWCIndividualConnection = useRef();
  const RU_NWCYardTaps = useRef();
  const RU_NWCMDU = useRef();
  const RU_NWCKiosks = useRef();
  const RU_Newbeneficiaries_NewConnections = useRef();
  const RU_Beneficiaries_ImprovedService = useRef();
  const RU_Newbeneficiaries_ProtectedSprings = useRef();
  const RU_Beneficiaries_IS_ProtectedSprings = useRef();
  const RU_QualityStandard = useRef();
  const RU_WaterProduced = useRef();
  const RU_RegistrationStatus = useRef();
  const RU_SDM_From = useRef();
  const RU_SDM_To = useRef();
  const RU_WaterStorage = useRef();
  const RU_SupplyHours = useRef();
  const RU_New_Meters = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.RU_NRW = RU_NRW.current.value;
    d.RU_TotalBilled = RU_TotalBilled.current.value;
    d.RU_TotalCollected = RU_TotalCollected.current.value;
    d.RU_WaterPipelineConstructed = RU_WaterPipelineConstructed.current.value;
    d.RU_NWCIndividualConnection = RU_NWCIndividualConnection.current.value;
    d.RU_NWCYardTaps = RU_NWCYardTaps.current.value;
    d.RU_NWCMDU = RU_NWCMDU.current.value;
    d.RU_NWCKiosks = RU_NWCKiosks.current.value;
    d.RU_Newbeneficiaries_NewConnections =
      RU_Newbeneficiaries_NewConnections.current.value;
    d.RU_Beneficiaries_ImprovedService =
      RU_Beneficiaries_ImprovedService.current.value;
    d.RU_Newbeneficiaries_ProtectedSprings =
      RU_Newbeneficiaries_ProtectedSprings.current.value;
    d.RU_Beneficiaries_IS_ProtectedSprings =
      RU_Beneficiaries_IS_ProtectedSprings.current.value;
    d.RU_QualityStandard = RU_QualityStandard.current.value;
    d.RU_WaterProduced = RU_WaterProduced.current.value;
    d.RU_RegistrationStatus = RU_RegistrationStatus.current.value;
    d.RU_SDM_From = RU_SDM_From.current.value;
    d.RU_WaterStorage = RU_WaterStorage.current.value;
    d.RU_SDM_To = RU_SDM_To.current.value;
    d.RU_SupplyHours = RU_SupplyHours.current.value;
    d.RU_New_Meters = RU_New_Meters.current.value;
    setBody(d);

    setLoading(true);

    fetch(`/api/rmf/${props?.editing}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("Water Resource Management");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <Input
        type="number"
        ref={RU_NRW}
        value={props?.data?.RU_NRW}
        label="Percentage of Non-Revenue Water (%)"
      />
      <h4>Revenue Collection</h4>
      <div className="div2equal">
        <Input
          type="number"
          ref={RU_TotalBilled}
          value={props?.data?.RU_TotalBilled}
          label="Total Billing (KSh)"
        />
        <Input
          type="number"
          ref={RU_TotalCollected}
          value={props?.data?.RU_TotalCollected}
          label="Total Collection (KSh)"
        />
      </div>
      <Input
        type="number"
        ref={RU_WaterPipelineConstructed}
        value={props?.data?.RU_WaterPipelineConstructed}
        label="Length of water distribution pipelines constructed/rehabilitated (Km)"
      />
      <h4>Number of new water connections</h4>
      <div className="div4equal">
        <Input
          type="number"
          ref={RU_NWCIndividualConnection}
          value={props?.data?.RU_NWCIndividualConnection}
          label="Individual Connections"
        />
        <Input
          type="number"
          ref={RU_NWCYardTaps}
          value={props?.data?.RU_NWCYardTaps}
          label="Yard taps"
        />
        <Input
          type="number"
          ref={RU_NWCMDU}
          value={props?.data?.RU_NWCMDU}
          label="MDU (small,medium,large)"
        />
        <Input
          type="number"
          ref={RU_NWCKiosks}
          value={props?.data?.RU_NWCKiosks}
          label="Kiosks"
        />
      </div>
      <Input
        type="number"
        ref={RU_Newbeneficiaries_NewConnections}
        value={props?.data?.RU_Newbeneficiaries_NewConnections}
        label="Number of new beneficiaries of water from new connections"
      />
      <Input
        type="number"
        ref={RU_Beneficiaries_ImprovedService}
        value={props?.data?.RU_Beneficiaries_ImprovedService}
        label="Number of beneficiaries benefiting from improved service"
      />
      <h4>
        Protected springs and communal water points not managed by enterprises
      </h4>
      <div className="div2equal">
        <Input
          type="number"
          ref={RU_Newbeneficiaries_ProtectedSprings}
          value={props?.data?.RU_Newbeneficiaries_ProtectedSprings}
          label="Number of new beneficiaries"
        />
        <Input
          type="number"
          ref={RU_Beneficiaries_IS_ProtectedSprings}
          value={props?.data?.RU_Beneficiaries_IS_ProtectedSprings}
          label="Number of beneficiaries benefitting from improved service"
        />
      </div>
      <Select
        label="Awarded a quality standard for utilities"
        ref={RU_QualityStandard}
        value={props?.data?.RU_QualityStandard}
        data={[
          "None",
          "QMS-ISO 9001:2015 (KEBS)",
          "Lab Testing ISO17025:2017 (KENAS)",
          "Other",
        ]}
      />
      <Input
        type="number"
        ref={RU_WaterProduced}
        value={props?.data?.RU_WaterProduced}
        label="Volume of water produced (Cubic Meters)"
      />
      <Select
        data={["Current", "Expired", "Not Registered", "Other"]}
        ref={RU_RegistrationStatus}
        value={props?.data?.RU_RegistrationStatus}
        label="Registration Status"
      />
      <h4>Change in service delivery model</h4>
      <div className="div2equal">
        <Input
          type="text"
          ref={RU_SDM_From}
          value={props?.data?.RU_SDM_From}
          label="From"
        />
        <Input
          type="text"
          ref={RU_SDM_To}
          value={props?.data?.RU_SDM_To}
          label="To"
        />
      </div>
      <Input
        type="number"
        ref={RU_WaterStorage}
        value={props?.data?.RU_WaterStorage}
        label="Water storage (Cubic Meters)"
      />
      <Input
        type="number"
        value={props?.data?.RU_SupplyHours}
        ref={RU_SupplyHours}
        label="Hours of supply (Hrs)"
      />
      <Input
        type="number"
        ref={RU_New_Meters}
        value={props?.data?.RU_New_Meters}
        label="Number of new meters installed"
      />
      <h6>{error}</h6>
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Step3 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [body, setBody] = useState({
    WRM_PES_Actions: 0,
    WRM_Trees_Planted: 0,
    WRM_Springs_Protected: 0,
    WRM_Terrace_Kilometers: 0,
    WRM_Riparian_Rehabilitated: 0,
    WRM_Forest_Rehabilitated: 0,
    WRM_WRUAs_SCMP: 0,
    WRM_Tree_Nurseries: 0,
    WRM_Riverine_Protected: 0,
    WRM_Beneficiaries_Improved_WRM: 0,
    WRM_Beneficiaries_IGA_HH: 0,
    WRM_Beneficiaries_IGA_Total: 0,
    WRM_Beneficiaries_IGA_Type: 0,
    WRM_WRUA_Committees_Trained: 0,
    WRM_Data_Digital_Solution: "",
  });
  const WRM_PES_Actions = useRef();
  const WRM_Trees_Planted = useRef();
  const WRM_Springs_Protected = useRef();
  const WRM_Terrace_Kilometers = useRef();
  const WRM_Riparian_Rehabilitated = useRef();
  const WRM_Forest_Rehabilitated = useRef();
  const WRM_WRUAs_SCMP = useRef();
  const WRM_Tree_Nurseries = useRef();
  const WRM_Riverine_Protected = useRef();
  const WRM_Beneficiaries_Improved_WRM = useRef();
  const WRM_Beneficiaries_IGA_HH = useRef();
  const WRM_Beneficiaries_IGA_Total = useRef();
  const WRM_Beneficiaries_IGA_Type = useRef();
  const WRM_WRUA_Committees_Trained = useRef();
  const WRM_Data_Digital_Solution = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.WRM_PES_Actions = WRM_PES_Actions.current.value;
    d.WRM_Trees_Planted = WRM_Trees_Planted.current.value;
    d.WRM_Springs_Protected = WRM_Springs_Protected.current.value;
    d.WRM_Terrace_Kilometers = WRM_Terrace_Kilometers.current.value;
    d.WRM_Riparian_Rehabilitated = WRM_Riparian_Rehabilitated.current.value;
    d.WRM_Forest_Rehabilitated = WRM_Forest_Rehabilitated.current.value;
    d.WRM_WRUAs_SCMP = WRM_WRUAs_SCMP.current.value;
    d.WRM_Tree_Nurseries = WRM_Tree_Nurseries.current.value;
    d.WRM_Riverine_Protected = WRM_Riverine_Protected.current.value;
    d.WRM_Beneficiaries_Improved_WRM =
      WRM_Beneficiaries_Improved_WRM.current.value;
    d.WRM_Beneficiaries_IGA_HH = WRM_Beneficiaries_IGA_HH.current.value;
    d.WRM_Beneficiaries_IGA_Total = WRM_Beneficiaries_IGA_Total.current.value;
    d.WRM_Beneficiaries_IGA_Type = WRM_Beneficiaries_IGA_Type.current.value;
    d.WRM_WRUA_Committees_Trained = WRM_WRUA_Committees_Trained.current.value;
    d.WRM_Data_Digital_Solution = WRM_Data_Digital_Solution.current.value;

    setBody(d);

    setLoading(true);

    fetch(`/api/rmf/${props.editing}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("Finance and Private Sector Engagement");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <Input
        type="number"
        ref={WRM_PES_Actions}
        value={props?.data?.WRM_PES_Actions}
        label="Number of PES action plans developed"
      />
      <Input
        type="number"
        ref={WRM_Trees_Planted}
        value={props?.data?.WRM_Trees_Planted}
        label="Number of trees planted"
      />
      <Input
        type="number"
        ref={WRM_Springs_Protected}
        value={props?.data?.WRM_Springs_Protected}
        label="Number of springs protected"
      />
      <Input
        type="number"
        ref={WRM_Terrace_Kilometers}
        value={props?.data?.WRM_Terrace_Kilometers}
        label="Number of kilometers of terrace"
      />

      <h4>Number of hectares of land rehabilitated</h4>
      <div className="div2equal">
        <Input
          type="number"
          ref={WRM_Riparian_Rehabilitated}
          value={props?.data?.WRM_Riparian_Rehabilitated}
          label="Riparian"
        />
        <Input
          type="number"
          ref={WRM_Forest_Rehabilitated}
          value={props?.data?.WRM_Forest_Rehabilitated}
          label="Forest"
        />
      </div>
      <Input
        type="number"
        ref={WRM_WRUAs_SCMP}
        value={props?.data?.WRM_WRUAs_SCMP}
        label="Number of WRUAs with SCMP"
      />
      <Input
        type="number"
        ref={WRM_Tree_Nurseries}
        value={props?.data?.WRM_Tree_Nurseries}
        label="Number of tree nurseries established"
      />
      <Input
        type="number"
        ref={WRM_Riverine_Protected}
        value={props?.data?.WRM_Riverine_Protected}
        label="Kilometers of riverine protected"
      />
      <Input
        type="number"
        ref={WRM_Beneficiaries_Improved_WRM}
        value={props?.data?.WRM_Beneficiaries_Improved_WRM}
        label="Number of beneficiaries benefiting from adoption and implementation of measures to improve water resources management"
      />
      <h4>Number of beneficiaries benefiting from alternative/enhanced IGAs</h4>
      <div className="div3equal">
        <Input
          type="number"
          ref={WRM_Beneficiaries_IGA_HH}
          value={props?.data?.WRM_Beneficiaries_IGA_HH}
          label="HH"
        />
        <Input
          type="number"
          ref={WRM_Beneficiaries_IGA_Total}
          value={props?.data?.WRM_Beneficiaries_IGA_Total}
          label="Total"
        />
        <Select
          data={["IGA1", "IGA2", "IGA3", "Other"]}
          ref={WRM_Beneficiaries_IGA_Type}
          value={props?.data?.WRM_Beneficiaries_IGA_Type}
          label="IGA Type"
        />
      </div>
      <Input
        type="number"
        ref={WRM_WRUA_Committees_Trained}
        value={props?.data?.WRM_WRUA_Committees_Trained}
        label="Number of WRUA committees trained on effective WRM practices"
      />
      <Input
        type="text"
        ref={WRM_Data_Digital_Solution}
        value={props?.data?.WRM_Data_Digital_Solution}
        label="Name of Data management and digital solution adopted and operationalized/functional"
      />
      <h6>{error}</h6>
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Step4 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [body, setBody] = useState({
    FPS_PrivateFunding_Own: 0,
    FPS_PrivateFunding_Debt: 0,
    FPS_PrivateFunding_Leveraged: 0,
    FPS_PrivateFunding_Source: "",
    FPS_PhilanthropicFunding: 0,
    FPS_PhilanthropicFunding_Source: "",
    FPS_PublicFunding_CountyGov: 0,
    FPS_PublicFunding_NationalGov: 0,
    FPS_NewBeneficiaries_PrivateFunding: 0,
    FPS_IS_Beneficiaries_PrivateFunding: 0,
    FPS_NewBeneficiaries_PhilanthropicFunding: 0,
    FPS_IS_Beneficiaries_PhilanthropicFunding: 0,
    FPS_NewBeneficiaries_PublicFunding: 0,
    FPS_IS_Beneficiaries_PublicFunding: 0,
  });
  const FPS_PrivateFunding_Own = useRef();
  const FPS_PrivateFunding_Debt = useRef();
  const FPS_PrivateFunding_Leveraged = useRef();
  const FPS_PrivateFunding_Source = useRef();
  const FPS_PhilanthropicFunding = useRef();
  const FPS_PhilanthropicFunding_Source = useRef();
  const FPS_PublicFunding_CountyGov = useRef();
  const FPS_PublicFunding_NationalGov = useRef();
  const FPS_NewBeneficiaries_PrivateFunding = useRef();
  const FPS_IS_Beneficiaries_PrivateFunding = useRef();
  const FPS_NewBeneficiaries_PhilanthropicFunding = useRef();
  const FPS_IS_Beneficiaries_PhilanthropicFunding = useRef();
  const FPS_NewBeneficiaries_PublicFunding = useRef();
  const FPS_IS_Beneficiaries_PublicFunding = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.FPS_PrivateFunding_Own = FPS_PrivateFunding_Own.current.value;
    d.FPS_PrivateFunding_Debt = FPS_PrivateFunding_Debt.current.value;
    d.FPS_PrivateFunding_Source = FPS_PrivateFunding_Source.current.value;
    d.FPS_PhilanthropicFunding = FPS_PhilanthropicFunding.current.value;
    d.FPS_PhilanthropicFunding_Source =
      FPS_PhilanthropicFunding_Source.current.value;
    d.FPS_PublicFunding_CountyGov = FPS_PublicFunding_CountyGov.current.value;
    d.FPS_PublicFunding_NationalGov =
      FPS_PublicFunding_NationalGov.current.value;
    d.FPS_NewBeneficiaries_PrivateFunding =
      FPS_NewBeneficiaries_PrivateFunding.current.value;
    d.FPS_IS_Beneficiaries_PrivateFunding =
      FPS_IS_Beneficiaries_PrivateFunding.current.value;
    d.FPS_NewBeneficiaries_PhilanthropicFunding =
      FPS_NewBeneficiaries_PhilanthropicFunding.current.value;
    d.FPS_IS_Beneficiaries_PhilanthropicFunding =
      FPS_IS_Beneficiaries_PhilanthropicFunding.current.value;
    d.FPS_NewBeneficiaries_PublicFunding =
      FPS_NewBeneficiaries_PublicFunding.current.value;
    d.FPS_IS_Beneficiaries_PublicFunding =
      FPS_IS_Beneficiaries_PublicFunding.current.value;
    d.FPS_PrivateFunding_Leveraged = FPS_PrivateFunding_Leveraged.current.value;

    setBody(d);

    setLoading(true);

    fetch(`/api/rmf/${props.editing}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("Policy and Governance");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <h4>Amount of private funding invested by WSPs/Enterprises</h4>
      <div className="div3equal">
        <Input
          type="number"
          ref={FPS_PrivateFunding_Own}
          value={props?.data?.FPS_PrivateFunding_Own}
          label="Own Capital (KSh)"
        />
        <Input
          type="number"
          ref={FPS_PrivateFunding_Debt}
          value={props?.data?.FPS_PrivateFunding_Debt}
          label="Debt (KSh)"
        />
        <Input
          type="number"
          ref={FPS_PrivateFunding_Leveraged}
          value={props?.data?.FPS_PrivateFunding_Leveraged}
          label="Leveraged (KSh)"
        />
      </div>
      <Select
        data={["Bank", "MFI", "SACCO", "Other"]}
        ref={FPS_PrivateFunding_Source}
        value={props?.data?.FPS_PrivateFunding_Source}
        label="Source of private funding"
      />
      <div className="div2equal">
        <Input
          type="number"
          ref={FPS_PhilanthropicFunding}
          value={props?.data?.FPS_PhilanthropicFunding}
          label="Amount of philanthropic funding mobiled by WSPs/Enterprises"
        />
        <Select
          data={["Corporate CSR", "NGOs", "Foundations", "Other"]}
          ref={FPS_PhilanthropicFunding_Source}
          value={props?.data?.FPS_PhilanthropicFunding_Source}
          label="Source of philanthropic funding"
        />
      </div>

      <h4>Amount of public funding invested by WSPs/Enterprises</h4>
      <div className="div2equal">
        <Input
          type="number"
          ref={FPS_PublicFunding_CountyGov}
          value={props?.data?.FPS_PublicFunding_CountyGov}
          label="County Government (KSh)"
        />
        <Input
          type="number"
          ref={FPS_PublicFunding_NationalGov}
          value={props?.data?.FPS_PublicFunding_NationalGov}
          label="National Government/water works agencies (KSh)"
        />
      </div>

      <h4>Private funding investment</h4>
      <div className="div2equal">
        <Input
          type="number"
          ref={FPS_NewBeneficiaries_PrivateFunding}
          value={props?.data?.FPS_NewBeneficiaries_PrivateFunding}
          label="Number of new beneficiaries benefiting from private funding investment"
        />
        <Input
          type="number"
          ref={FPS_IS_Beneficiaries_PrivateFunding}
          value={props?.data?.FPS_IS_Beneficiaries_PrivateFunding}
          label="Number of beneficiaries benefiting from improved service"
        />
      </div>
      <h4>Philanthropic funding investment</h4>
      <div className="div2equal">
        <Input
          type="number"
          ref={FPS_NewBeneficiaries_PhilanthropicFunding}
          value={props?.data?.FPS_NewBeneficiaries_PhilanthropicFunding}
          label="Number of new beneficiaries benefiting from philanthropic funding investment"
        />
        <Input
          type="number"
          ref={FPS_IS_Beneficiaries_PhilanthropicFunding}
          value={props?.data?.FPS_IS_Beneficiaries_PhilanthropicFunding}
          label="Number of beneficiaries benefiting from improved service"
        />
      </div>
      <h4>Public funding investment</h4>
      <div className="div2equal">
        <Input
          type="number"
          ref={FPS_NewBeneficiaries_PublicFunding}
          value={props?.data?.FPS_NewBeneficiaries_PublicFunding}
          label="Number of new beneficiaries benefiting from public funding investment"
        />
        <Input
          type="number"
          ref={FPS_IS_Beneficiaries_PublicFunding}
          value={props?.data?.FPS_IS_Beneficiaries_PublicFunding}
          label="Number of beneficiaries benefiting from improved service"
        />
      </div>
      <h6>{error}</h6>
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Step5 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [body, setBody] = useState({
    PG_ProPoorPolicies_Developed: 0,
    PG_MeteringPolicies_Developed: 0,
    PG_ClimateChangePolicies_Developed: 0,
    PG_MarketingPolicies_Developed: 0,
    PG_BusinessPlans_Developed: 0,
    PG_WaterSafetyPlan_Developed: 0,
    PG_PIPs_Developed: 0,
    PG_CIDP_Developed: 0,
    PG_WaterSanitationAct_Developed: 0,
    PG_NRWManual_Developed: 0,
    PG_ServiceCharter_Developed: 0,
    PG_Sewerage_Policy: 0,
    PG_Strategic_Plan: 0,
    PG_Sanctions_Rewards_Policy: 0,
    PG_Operations_Maintenance: 0,
    PG_HR_Policy: 0,
    PG_Finance_Policy: 0,
    PG_Officials_Trained: 0,
    Description: "",
  });
  const PG_ProPoorPolicies_Developed = useRef();
  const PG_MeteringPolicies_Developed = useRef();
  const PG_ClimateChangePolicies_Developed = useRef();
  const PG_MarketingPolicies_Developed = useRef();
  const PG_BusinessPlans_Developed = useRef();
  const PG_WaterSafetyPlan_Developed = useRef();
  const PG_PIPs_Developed = useRef();
  const PG_CIDP_Developed = useRef();
  const PG_WaterSanitationAct_Developed = useRef();
  const PG_NRWManual_Developed = useRef();
  const PG_ServiceCharter_Developed = useRef();
  const PG_Officials_Trained = useRef();
  const PG_Sewerage_Policy = useRef();
  const PG_Strategic_Plan = useRef();
  const PG_Sanctions_Rewards_Policy = useRef();
  const PG_Operations_Maintenance = useRef();
  const PG_HR_Policy = useRef();
  const PG_Finance_Policy = useRef();
  const Description = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.PG_ProPoorPolicies_Developed = PG_ProPoorPolicies_Developed.current.value;
    d.PG_MeteringPolicies_Developed =
      PG_MeteringPolicies_Developed.current.value;
    d.PG_ClimateChangePolicies_Developed =
      PG_ClimateChangePolicies_Developed.current.value;
    d.PG_MarketingPolicies_Developed =
      PG_MarketingPolicies_Developed.current.value;
    d.PG_BusinessPlans_Developed = PG_BusinessPlans_Developed.current.value;
    d.PG_WaterSafetyPlan_Developed = PG_WaterSafetyPlan_Developed.current.value;
    d.PG_PIPs_Developed = PG_PIPs_Developed.current.value;
    d.PG_CIDP_Developed = PG_CIDP_Developed.current.value;
    d.PG_WaterSanitationAct_Developed =
      PG_WaterSanitationAct_Developed.current.value;
    d.PG_NRWManual_Developed = PG_NRWManual_Developed.current.value;
    d.PG_ServiceCharter_Developed = PG_ServiceCharter_Developed.current.value;
    d.PG_Sewerage_Policy = PG_Sewerage_Policy.current.value;
    d.PG_Strategic_Plan = PG_Strategic_Plan.current.value;
    d.PG_Sanctions_Rewards_Policy = PG_Sanctions_Rewards_Policy.current.value;
    d.PG_Operations_Maintenance = PG_Operations_Maintenance.current.value;
    d.PG_HR_Policy = PG_HR_Policy.current.value;
    d.PG_Finance_Policy = PG_Finance_Policy.current.value;
    d.PG_Officials_Trained = PG_Officials_Trained.current.value;

    d.Description = Description.current.value;
    setBody(d);

    setLoading(true);

    fetch(`/api/rmf/${props.editing}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("Sign Off");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <div className="div3equal">
        <Input
          type="number"
          ref={PG_ProPoorPolicies_Developed}
          value={
            props?.data?.PG_ProPoorPolicies_Developed
              ? props?.data?.PG_ProPoorPolicies_Developed
              : 0
          }
          label="Pro Poor Policies developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_MeteringPolicies_Developed}
          value={
            props?.data?.PG_MeteringPolicies_Developed
              ? props?.data?.PG_MeteringPolicies_Developed
              : 0
          }
          label="Metering Policies developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_Sewerage_Policy}
          value={
            props?.data?.PG_Sewerage_Policy
              ? props?.data?.PG_Sewerage_Policy
              : 0
          }
          label="Sewerage Policies Developed"
        />
      </div>
      <div className="div3equal">
        <Input
          type="number"
          ref={PG_ClimateChangePolicies_Developed}
          value={
            props?.data?.PG_ClimateChangePolicies_Developed
              ? props?.data?.PG_ClimateChangePolicies_Developed
              : 0
          }
          label="Climate Change Policies developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_MarketingPolicies_Developed}
          value={
            props?.data?.PG_MarketingPolicies_Developed
              ? props?.data?.PG_MarketingPolicies_Developed
              : 0
          }
          label="Marketing Policies developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_Strategic_Plan}
          value={
            props?.data?.PG_Strategic_Plan ? props?.data?.PG_Strategic_Plan : 0
          }
          label="Strategic Plans developed/reviewed"
        />
      </div>
      <div className="div3equal">
        <Input
          type="number"
          ref={PG_BusinessPlans_Developed}
          value={
            props?.data?.PG_BusinessPlans_Developed
              ? props?.data?.PG_BusinessPlans_Developed
              : 0
          }
          label="Number of Business Plans developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_WaterSafetyPlan_Developed}
          value={
            props?.data?.PG_WaterSafetyPlan_Developed
              ? props?.data?.PG_WaterSafetyPlan_Developed
              : 0
          }
          label="Water Safety Plans developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_Finance_Policy}
          value={
            props?.data?.PG_Finance_Policy ? props?.data?.PG_Finance_Policy : 0
          }
          label="Finance Policies developed/reviewed"
        />
      </div>
      <div className="div3equal">
        <Input
          type="number"
          ref={PG_PIPs_Developed}
          value={
            props?.data?.PG_PIPs_Developed ? props?.data?.PG_PIPs_Developed : 0
          }
          label="PIPs developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_CIDP_Developed}
          value={
            props?.data?.PG_CIDP_Developed ? props?.data?.PG_CIDP_Developed : 0
          }
          label="CIDPs developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_HR_Policy}
          value={props?.data?.PG_HR_Policy ? props?.data?.PG_HR_Policy : 0}
          label="HR Policies developed/reviewed"
        />
      </div>
      <div className="div3equal">
        <Input
          type="number"
          ref={PG_WaterSanitationAct_Developed}
          value={
            props?.data?.PG_WaterSanitationAct_Developed
              ? props?.data?.PG_WaterSanitationAct_Developed
              : 0
          }
          label="Water and Sanitation Acts developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_NRWManual_Developed}
          value={
            props?.data?.PG_NRWManual_Developed
              ? props?.data?.PG_NRWManual_Developed
              : 0
          }
          label="NRW Manuals developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_Operations_Maintenance}
          value={
            props?.data?.PG_Operations_Maintenance
              ? props?.data?.PG_Operations_Maintenance
              : 0
          }
          label="Operations and Maintenance Policies Developed/reviewed"
        />
      </div>
      <div className="div3equal">
        <Input
          type="number"
          ref={PG_ServiceCharter_Developed}
          value={
            props?.data?.PG_ServiceCharter_Developed
              ? props?.data?.PG_ServiceCharter_Developed
              : 0
          }
          label="Service Charters developed/reviewed"
        />
        <Input
          type="number"
          ref={PG_Sanctions_Rewards_Policy}
          value={
            props?.data?.PG_Sanctions_Rewards_Policy
              ? props?.data?.PG_Sanctions_Rewards_Policy
              : 0
          }
          label="Sanctions and Rewards_Policy"
        />
        <Input
          type="number"
          ref={PG_Officials_Trained}
          value={
            props?.data?.PG_Officials_Trained
              ? props?.data?.PG_Officials_Trained
              : 0
          }
          label="Number of county and government officials trained in corporate governance"
        />
      </div>

      <br />
      <h4>Brief Description if necessary</h4>
      <Input
        type="textarea"
        ref={Description}
        value={props?.data?.Description}
        label="Number of county and government officials trained in corporate governance"
      />
      <br />

      <h6>{error}</h6>
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Step6 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [rep, setRep] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [body, setBody] = useState({
    ERepName: null,
    ERepDesignation: null,
    WKWPRepName: null,
    WKWPRepDesignation: null,
    UserID: null,
    File: null,
  });

  const ERepName = useRef();
  const ERepDesignation = useRef();
  const ERepSignature = useRef();
  const WKWPRepSignature = useRef();
  const myFile = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`/api/auth`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((err) => {});
  }, []);

  function searchRepName(role, q) {
    fetch(`/api/auth/seachbyname/${role}/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        console.log(data);
        setRep(data);
      })
      .catch((e) => {});
  }

  const RepList = (params) => {
    return (
      <div
        className="so_list"
        onClick={() => {
          setSelectedRep(params.item);
          setRep(null);
        }}
      >
        <h4>{params.item.Name}</h4>
        <p>Phone: {params.item.Phone}</p>
        <p>Position: {params.item.Position}</p>
      </div>
    );
  };

  const updateDocument = async () => {
    setError("");
    let d = body;
    d.ERepName = ERepName.current.value;
    d.ERepDesignation = ERepDesignation.current.value;
    d.WKWPRepName = selectedRep ? selectedRep.Name : null;
    d.WKWPRepDesignation = selectedRep ? selectedRep.Position : null;
    d.UserID = selectedRep ? selectedRep.UserID : null;
    if (myFile.current.files.length > 0) {
      d.File = myFile.current.files.length > 0 ? myFile.current.files[0] : null;

      d.File = await convertFileToBase64(d.File);
    }
    d.ERepSignature = ERepSignature.current.toDataURL();
    d.WKWPRepSignature = WKWPRepSignature.current.toDataURL();
    console.log("RMF data:" + d);
    setBody(d);
    setLoading(true);

    fetch(`/api/rmf/${props.editing}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/rmf-list";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  function getFile() {
    setLoading(true);
    fetch(`/api/uploads/${props.data.File}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then((data) => {
        const imageURL = URL.createObjectURL(data);
        window.open(imageURL, "_blank");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="taf">
      <h4>Sign Off</h4>
      <hr />
      <h3>Sign Off - WKWP Representative</h3>
      <div className="div2equal">
        <div className="search">
          <Select
            setChanged={(v) => {
              let d = data.filter((obj) => obj.Name === v);
              if (d.length > 0) {
                setSelectedRep(d[0]);
              }
              console.log(selectedRep);
            }}
            data={data.map((obj) => obj.Name)}
            label="Select WKWP Representative"
          />
          {selectedRep && (
            <div>
              <h4>
                <b>Name:</b> {selectedRep.Name}
              </h4>
              <h4>
                <b>Designation:</b> {selectedRep.Position}
              </h4>
            </div>
          )}
        </div>
        <SignatureInput
          ref={WKWPRepSignature}
          value={props.data?.WKWPRepSignature}
          label="Entity Represenative"
        />
      </div>

      <h3>Sign Off - Entity Representative</h3>
      <div className="div2equal">
        <div>
          <Input
            value={props.data?.ERepName}
            ref={ERepName}
            type="text"
            label="Name *"
          />
          <br />
          <Input
            value={props.data?.ERepDesignation}
            ref={ERepDesignation}
            type="text"
            label="Designation *"
          />
        </div>
        <SignatureInput
          ref={ERepSignature}
          value={props.data?.ERepSignature}
          label="Entity Represenative Signature"
        />
      </div>

      <h3>Upload Scanned RMF (Optional)</h3>
      {props.data?.File != null && (
        <h4
          onClick={() => {
            getFile();
          }}
          className="view"
        >
          <FontAwesomeIcon icon={faDownload} />
          View uploaded file
        </h4>
      )}
      <div className="file-input">
        <input ref={myFile} type="file" accept=".pdf" />
        <FontAwesomeIcon onClick={() => {}} icon={faTrash} className="ic" />
      </div>

      <h6>{error}</h6>
      <Button handleClick={updateDocument} value="Finish" />
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Error = (props) => {
  return (
    <div className="error">
      <p>This form is only available after filling the basic details</p>
    </div>
  );
};
