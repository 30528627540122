import {
  faArrowsRotate,
  faDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowsRotate, FaDownload } from "react-icons/fa6";
import { useState } from "react";
import "../../Styles/workplan.scss";
import CountyWP from "./CountyWP";
import DailyWP from "./DailyWP";
import MonthWP from "./MonthWP";
import SoWP from "./SoWP";

export default function WorkPlan(props) {
  const [active, setActive] = useState("Daily View");
  const [refresh, setRefresh] = useState(false);
  const [download, setDownload] = useState(false);

  return (
    <div className="workplan">
      <div className="container">
        <div className="topbar">
          {props.role !== "Regular User" && props.role !== "Guest" && (
            <div
              onClick={() => {
                window.location.href = "/wp/new";
              }}
              className="new"
            >
              <FontAwesomeIcon icon={faPlus} />
              <p>Create Work Plan</p>
            </div>
          )}

          <div className="top">
            <FaArrowsRotate
              onClick={() => {
                setRefresh(!refresh);
              }}
              className="fa-icon"
            />
            <FaDownload
              onClick={() => {
                setDownload(true);
              }}
              className="fa-icon"
            />

            <TopItem active={active} setActive={setActive} txt="Daily View" />
            <TopItem active={active} setActive={setActive} txt="Month View" />
          </div>
        </div>

        {active === "Daily View" && (
          <DailyWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
        {active === "Month View" && (
          <MonthWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
      </div>
    </div>
  );
}

const TopItem = (props) => {
  return (
    <div
      className={props.txt === props.active ? "active" : "topitem"}
      onClick={() => {
        props.setActive(props.txt);
      }}
    >
      <p>{props.txt}</p>
    </div>
  );
};
