import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../../../Styles/dataentry.scss";
import ARFDetails from "./ARFDetails";

export default function ARFNew(props) {
  return (
    <div className="dataentry">
      <div className="new">
        <div className="topbar">
          <h4>New Activity Registration Form</h4>
          <p
            onClick={() => {
              window.location.href = "/dataentry/arf-list";
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} /> List
          </p>
        </div>
        <div className="arf">
          <ARFDetails />
        </div>
      </div>
    </div>
  );
}
