import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/beneficiary.scss";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import IndicatorBox from "./IndicatorBox";
import SelectedIndicator from "./SelectedIndicator";

export default function Indicators() {
  const [offset, setOffset] = useState(0);
  const { indicators } = require("../../assets/data/data");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [ID, setID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [indicatorDetails, setIndicatorDetails] = useState();
  const selected = useRef();

  useEffect(() => {
    if (ID !== null) {
      setIndicatorDetails(ID);
    } else setIndicatorDetails(indicators[0]);
  }, [ID]);

  return (
    <div className="beneficiary">
      <div className="list">
        <div className="utp">
          <h3>Update KPIs</h3>
        </div>
        <hr />

        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {indicators.map((item, index) => {
                    return (
                      <IndicatorBox
                        key={index}
                        item={item}
                        indicatorDetails={indicatorDetails}
                        setID={setID}
                        selected={selected}
                      />
                    );
                  })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <h4>Indicator Details</h4>
                <hr />

                {console.log(indicatorDetails)}
                {indicatorDetails ? (
                  indicatorDetails && (
                    <SelectedIndicator
                      setLoading={setLoading}
                      indicatorDetails={indicatorDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      url="indicators"
                    />
                  )
                ) : (
                  <>
                    <p>Click on a user to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {indicators.map((item, index) => {
                    return (
                      <IndicatorBox
                        key={index}
                        item={item}
                        indicatorDetails={indicatorDetails}
                        setID={setID}
                        selected={null}
                      />
                    );
                  })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>

            <div ref={selected}>
              <div className="selected">
                <h4>Indicator Details</h4>
                <hr />

                {indicatorDetails ? (
                  indicatorDetails && (
                    <SelectedIndicator
                      setLoading={setLoading}
                      indicatorDetails={indicatorDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      url="indicators"
                    />
                  )
                ) : (
                  <>
                    <p>Click on a user to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
      {clicked && (
        <Popup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </div>
  );
}

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const name = useRef();
  const type = useRef();
  const county = useRef();
  const subcounty = useRef();
  const ward = useRef();
  const village = useRef();
  const latitude = useRef();
  const longitude = useRef();
  const regulation = useRef();
  const sdm = useRef();
  const rehabilitation = useRef();

  const createBeneficiary = () => {
    const body = {
      Name: name.current.value,
      Type: type.current.value,
      County: county.current.value,
      SubCounty: subcounty.current.value,
      Ward: ward.current.value,
      Village: village.current.value,
      Longitude: longitude.current.value,
      Latitude: latitude.current.value,
      Regulation: regulation.current.value,
      SDM: sdm.current.value,
      DrinkingSystems_Rehabilitated: rehabilitation.current.value,
    };

    setError("");

    setLoading(true);
    fetch(`/api/waterproviders/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>New Water Provider</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <Input ref={name} type="text" label="Name *" />
              <Select ref={type} data={["WSP", "RWE"]} label="Type *" />
            </div>
            <div className="div2equal">
              <Select
                ref={county}
                data={[
                  " ",
                  "Bungoma",
                  "Busia",
                  "Homabay",
                  "Kakamega",
                  "Kisii",
                  "Kisumu",
                  "Migori",
                  "Siaya",
                ]}
                label="County *"
              />
              <Input ref={subcounty} type="text" label="Sub County *" />
            </div>

            <div className="div2equal">
              <Input ref={ward} type="text" label="Ward *" />
              <Input ref={village} type="text" label="Village *" />
            </div>

            <div className="div2equal">
              <Input
                ref={latitude}
                type="number"
                label="Latitude * (use (-) sign for South)"
              />
              <Input ref={longitude} type="number" label="Longitude *" />
            </div>
            <div className="div2equal">
              <Select
                ref={regulation}
                data={["YES", "NO"]}
                label="Is the WSP/RWE Regulated? *"
              />
              <Select
                ref={sdm}
                data={["YES", "NO"]}
                label="Does the WSP/RWE have an SDM? *"
              />
            </div>
            <div className="div2equal">
              <Input
                ref={rehabilitation}
                type="number"
                label="Number Drinking Water Systems Rehabilitated *"
              />
            </div>
            <h6>{error}</h6>
            <Button handleClick={createBeneficiary} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
