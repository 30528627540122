import React, { useEffect, useRef, useState } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Util/Button";
import Input from "../Util/Input";
import Loading from "../Util/Loading";

export default function SelectedIndicator(props) {
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props?.indicatorDetails) {
      props.setLoading(true);
      fetch(`/api/indicatorsmel/code/${props?.indicatorDetails?.Code}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          props.setLoading(false);
          console.log(data);
          setData(data);
        })
        .catch((error) => {
          props.setLoading(false);
        });
    }
  }, [props?.indicatorDetails, refresh]);

  const Item = (params) => {

    const handleDelete = (id) => {
      fetch(`/api/indicatorsmel/delete/${id}`, {
        method: "delete",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.success) {            
            params.setRefresh(!params.refresh);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return (
      <div className="itt div1auto">
        <p>Date: {params?.item?.Date}</p>
        <p className="div1auto" style={{width: "65px"}}>
          {params?.item?.Performance}
          <FontAwesomeIcon 
            icon={faTimes} 
            className="fa-icon"
            onClick={() => {
              handleDelete(params?.item?.ID);
            }}
          />
        </p>
      </div>
    );
  };

  return (
    <>
      <p>{props?.indicatorDetails?.Code}</p>
      <p>{props?.indicatorDetails?.Description}</p>

      <h4>Performance</h4>
      <hr />

      {data &&
        data.length > 0 &&
        data.map((item, i) => {
          return <Item key={i} item={item} refresh={refresh} setRefresh={setRefresh}/>;
        })}

      <div className="performance">
        <h6
          onClick={() => {
            setClicked(true);
          }}
        >
          Update Performance
        </h6>
      </div>
      {clicked && (
        <UpdatePopup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          indicatorDetails={props.indicatorDetails}
        />
      )}
    </>
  );
}

const UpdatePopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const performance = useRef();
  const date = useRef();

  const updateBeneficiary = () => {
    const body = {
      Code: props.indicatorDetails.Code,
      Performance: performance.current.value,
      Date: date.current.value,
    };

    let valid = true;
    const v = Object.values(body);

    v.map((i) => {
      if (i === "" || i === null) {
        valid = false;
      }
    });

    if (!valid) return setError("All fields are required!");

    setError("");

    setLoading(true);
    fetch(`/api/indicatorsmel/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>{props?.indicatorDetails?.Code}</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <hr />
        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input
              ref={date}
              value={props?.indicatorDetails?.Date}
              type="date"
              label="Date *"
            />
            <Input
              ref={performance}
              value={props?.indicatorDetails?.Performance}
              type="number"
              label="Performance *"
            />
            <p>{error}</p>
            <Button handleClick={updateBeneficiary} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
