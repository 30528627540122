import React, { useState } from "react";
import "../../Styles/userhome.scss";
import Beneficiaries from "./Beneficiaries";
import Indicators from "./Indicators";

export default function Mel(props) {
  const [active, setActive] = useState("Beneficiaries");

  return (
    <div className="userhome">
      <div className="vtop">
        <Item txt="Beneficiaries" active={active} setActive={setActive} />
        <Item txt="Update KPIs" active={active} setActive={setActive} />
      </div>
      {active === "Beneficiaries" && <Beneficiaries />}
      {active === "Update KPIs" && <Indicators />}
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active === props.txt ? "vactive" : "vitem"}
    >
      <p>{props.txt}</p>
    </div>
  );
};
