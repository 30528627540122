import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState, useEffect } from "react";
import Settings from "../components/Settings/Settings";
import WPStats from "../components/Stats/WPStats";
import Indicators from "../components/Indicators/Indicators";
import AllCounties from "../components/Counties/AllCounties";
import DataEntry from "../components/DataEntry/DataEntry";
import SingleCounty from "../components/Counties/SingleCounty/SingleCounty";
import TAFNew from "../components/DataEntry/TAF/TAFNew";
import TAFList from "../components/DataEntry/TAF/TAFList";
import WorkPlan from "../components/WorkPlan/WorkPlan";
import CreateWorkPlan from "../components/WorkPlan/CreateWorkPlan";
import ARFNew from "../components/DataEntry/ARF/ARFNew";
import ARFList from "../components/DataEntry/ARF/ARFList";
import RMFNew from "../components/DataEntry/RMF/RMFNew";
import RMFList from "../components/DataEntry/RMF/RMFList";
import ToolBuilder from "../components/ToolBuilder/ToolBuilder";
import NewTool from "../components/ToolBuilder/NewTool";
import UpdateTool from "../components/ToolBuilder/UpdateTool";
import PreviewTAF from "../components/DataEntry/TAF/PreviewTAF";
import PreviewRMF from "../components/DataEntry/RMF/PreviewRMF";
import UserHome from "../components/Users/UserHome";
import PreviewARF from "../components/DataEntry/ARF/PreviewARF";
import Mel from "../components/Mel/Mel";
import ReportsHome from "../components/Reports/ReportsHome";
import CountyAll from "../components/GIS/CountyAll";
import TAFUpdate from "../components/DataEntry/TAF/TAFUpdate";
import ARFUpdate from "../components/DataEntry/ARF/ARFUpdate";
import RMFUpdate from "../components/DataEntry/RMF/RMFUpdate";
import SingleView from "../components/Indicators/SingleView";
import MapTemplate from "../components/GIS/MapTemplate";
import TBData from "../components/ToolBuilder/TBData";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");
  const [shownavigation, setShowNavigation] = useState(false);
  const [role, setRole] = useState();

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
    if (token) {
      let decoded = jwt.decode(token);
      setRole(decoded.Role);
    }
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "225px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation
            showing={showing}
            setShowing={setShowing}
            shownavigation={shownavigation}
            role={role}
          />
        </div>
        <div className="right_panel">
          <Header
            showing={showing}
            setShowing={setShowing}
            setShowNavigation={setShowNavigation}
            shownavigation={setShowNavigation}
          />

          <div className="full">
            {pathname[1] === "" && <WPStats showing={showing} />}
            {pathname[1] === "indicators" && pathname.length == 2 && (
              <Indicators />
            )}
            {pathname[1] === "indicators" && pathname[2] === "singleview" && (
              <SingleView />
            )}

            {pathname[1] === "reports" && <ReportsHome showing={showing} />}

            {pathname[1] === "buildtool" &&
              pathname.length === 2 &&
              role !== "Guest" && <ToolBuilder />}

            {pathname[1] === "buildtool" &&
              pathname[2] === "new" &&
              role !== "Guest" && <NewTool />}

            {pathname[1] === "buildtool" &&
              pathname[2] === "update" &&
              role !== "Guest" && <UpdateTool />}

            {pathname[1] === "buildtool" &&
              pathname[2] === "data" &&
              role !== "Guest" && <TBData />}

            {pathname[1] === "dataentry" && pathname.length === 2 && (
              <DataEntry />
            )}
            {pathname[1] === "dataentry" &&
              pathname[2] === "taf" &&
              role !== "Guest" && <TAFNew />}

            {pathname[1] === "dataentry" && pathname[2] === "taf-list" && (
              <TAFList role={role} />
            )}
            {pathname[1] === "dataentry" &&
              pathname[2] === "taf-edit" &&
              role !== "Guest" && <TAFUpdate />}
            {pathname[1] === "dataentry" && pathname[2] === "preview-taf" && (
              <PreviewTAF />
            )}

            {pathname[1] === "dataentry" &&
              pathname[2] === "arf" &&
              role !== "Guest" && <ARFNew />}
            {pathname[1] === "dataentry" && pathname[2] === "arf-list" && (
              <ARFList role={role} />
            )}
            {pathname[1] === "dataentry" &&
              pathname[2] === "arf-edit" &&
              role !== "Guest" && <ARFUpdate />}
            {pathname[1] === "dataentry" && pathname[2] === "preview-arf" && (
              <PreviewARF />
            )}

            {pathname[1] === "dataentry" &&
              pathname[2] === "rmf" &&
              role !== "Guest" && <RMFNew />}
            {pathname[1] === "dataentry" && pathname[2] === "rmf-list" && (
              <RMFList role={role} />
            )}
            {pathname[1] === "dataentry" && pathname[2] === "rmf-edit" && (
              <RMFUpdate />
            )}
            {pathname[1] === "dataentry" && pathname[2] === "preview-rmf" && (
              <PreviewRMF />
            )}

            {pathname[1] === "gis" && pathname.length === 2 && (
              <CountyAll showing={showing} />
            )}

            {pathname[1] === "gis" && pathname[2] === "export" && (
              <MapTemplate showing={showing} path={pathname[2]} />
            )}

            {pathname[1] === "counties" && pathname.length === 2 && (
              <AllCounties />
            )}
            {pathname[1] === "counties" && pathname.length === 3 && (
              <SingleCounty />
            )}

            {pathname[1] === "counties" && pathname.length > 3 && (
              <MapTemplate />
            )}

            {pathname[1] === "wp" && pathname.length === 2 && (
              <WorkPlan role={role} />
            )}

            {pathname[1] === "wp" &&
              pathname[2] === "new" &&
              role !== "Guest" && <CreateWorkPlan />}

            {pathname[1] === "users" &&
              role !== "Regular User" &&
              role !== "Guest" && <UserHome role={role} />}

            {pathname[1] === "mel" &&
              role !== "Regular User" &&
              role !== "Guest" && <Mel />}

            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}
