import { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/dataentry.scss";
import form from "../../assets/imgs/form.png";
import CustomBarChart from "../Stats/CustomBarChart";
import CustomPieChart from "../Stats/CustomPieChart";
import Input from "../Util/Input";
import { BsFiletypeCsv } from "react-icons/bs";

export default function DataEntry(props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const [TAF, setTAF] = useState(null);
  const [ARF, setARF] = useState(null);
  const [RMF, setRMF] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

    useEffect(() => {
      const today = new Date();
      setStart(`${today.getFullYear() - 1}-10-01`);
      setEnd(`${today.getFullYear()}-09-30`);
      if (start !== "" && end !== "") {
        getNumbers();
        getTAF();
        getARF();
        getRMF();
      }
    }, []);

  useEffect(() => {
    if (start !== "" && end !== "") {
      getNumbers();
      getTAF();
      getARF();
      getRMF();
    }
  }, [start, end, refresh]);

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  async function getNumbers() {
    fetch(`/api/taf/stats/numbers/${start}/${end}`)
      
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        setData(dt);
      })
      .catch((e) => {});
  }

  async function getTAF() {
    fetch(`/api/taf/stats/charts/${start}/${end}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        setTAF(dt);
      })
      .catch((e) => {});
  }

  async function getARF() {
    fetch(`/api/arf/stats/charts/${start}/${end}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        setARF(dt);
      })
      .catch((e) => {});
  }

  async function getRMF() {
    fetch(`/api/rmf/stats/charts/${start}/${end}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        setRMF(dt);
      })
      .catch((e) => {});
  }

  return (
    <div className="dataentry">
      <div className="dthome">
        <div className="filters">
          <Input
            handleChange={(e) => {
              setStart(e);
            }}
            type="date"
            value={start}
            label="Start Date"
          />
          <Input
            handleChange={(e) => {
              setEnd(e);
            }}
            type="date"
            value={end}
            label="End Date"
          />
        </div>
        <div className="div31">
          <div className="top">
            <h3>Quick Links</h3>
            <p>Data entry forms</p>
            <hr />
            <div className="list">
              <Item
                url="dataentry/taf-list"
                txt="Technical Assistance Form"
                value={data?.TAF}
              />
              <Item
                url="dataentry/arf-list"
                txt="Activity Registration Form"
                value={data?.ARF}
              />
              <Item
                url="dataentry/rmf-list"
                txt="Results Monitoring Form"
                value={data?.RMF}
              />
            </div>
          </div>
          <div className="chrt">
            <h3>Forms Distribution</h3>
            <p>August</p>
            <CustomPieChart data={data?.Distribution} aspect={1.6} />
            <div className="save">
              <BsFiletypeCsv
                color="#0064B6"
                onClick={() => {
                  saveData(data?.Distribution);
                }}
              />
            </div>
          </div>
        </div>
        <div className="div31">
          <div className="chrt">
            <h3>Technical Assistance Form</h3>
            <p>County Distribution</p>
            <CustomBarChart data={TAF?.County} aspect={2} />
            <div className="save">
              <BsFiletypeCsv
                color="#0064B6"
                onClick={() => {
                  saveData(TAF?.County);
                }}
              />
            </div>
          </div>
          <div className="div2row">
            <div className="chrt">
              <h3>Technical Assistance</h3>
              <p>TA Provided</p>
              <CustomPieChart data={TAF?.TA} aspect={1.6} />
              <div className="save">
                <BsFiletypeCsv
                  color="#0064B6"
                  onClick={() => {
                    saveData(TAF?.TA);
                  }}
                />
              </div>
            </div>
            <div className="chrt">
              <h3>Beneficiary Type</h3>
              <p>Distribution</p>
              <CustomPieChart data={TAF?.Type} aspect={1.6} />
              <div className="save">
                <BsFiletypeCsv
                  color="#0064B6"
                  onClick={() => {
                    saveData(TAF?.Type);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="div31">
          <div className="chrt">
            <h3>Activity Registration Form</h3>
            <p>County Distribution</p>
            <CustomBarChart data={ARF?.County} aspect={2} />
            <div className="save">
              <BsFiletypeCsv
                color="#0064B6"
                onClick={() => {
                  saveData(ARF?.County);
                }}
              />
            </div>
          </div>
          <div className="div2row">
            <div className="chrt">
              <h3>Participants</h3>
              <p>Gender Distribution</p>
              <CustomPieChart data={ARF?.TA} aspect={1.6} />
              <div className="save">
                <BsFiletypeCsv
                  color="#0064B6"
                  onClick={() => {
                    saveData(ARF?.TA);
                  }}
                />
              </div>
            </div>
            <div className="chrt">
              <h3>Beneficiary Type</h3>
              <p>Distribution</p>
              <CustomPieChart data={ARF?.Type} aspect={1.6} />
              <div className="save">
                <BsFiletypeCsv
                  color="#0064B6"
                  onClick={() => {
                    saveData(ARF?.Type);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="div31">
          <div className="chrt">
            <h3>Results Monitoring Form</h3>
            <p>County Distribution</p>
            <CustomBarChart data={RMF?.County} aspect={isMobile ? 1 : 2} />
            <div className="save">
              <BsFiletypeCsv
                color="#0064B6"
                onClick={() => {
                  saveData(RMF?.County);
                }}
              />
            </div>
          </div>
          <div className="div2row">
            <div className="chrt">
              <h3>Technical Assistance</h3>
              <p>TA Provided</p>
              <CustomPieChart data={RMF?.TA} aspect={1.6} />
              <div className="save">
                <BsFiletypeCsv
                  color="#0064B6"
                  onClick={() => {
                    saveData(RMF?.TA);
                  }}
                />
              </div>
            </div>
            <div className="chrt">
              <h3>Beneficiary Type</h3>
              <p>Distribution</p>
              <CustomPieChart data={RMF?.Type} aspect={1.6} />
              <div className="save">
                <BsFiletypeCsv
                  color="#0064B6"
                  onClick={() => {
                    saveData(RMF?.Type);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div className="item">
      <div className="tt">
        <h4>{props.txt}</h4>
        <img src={form} alt="" />
      </div>

      <h2>{props.value}</h2>
      <button
        onClick={() => {
          window.location.href = "/" + props.url;
        }}
      >
        <i className="fa fa-plus"></i> List
      </button>
    </div>
  );
};
